import { IAConfig } from "src/app/app-config.model";
import { IAEventInfo, ICalendarWorkplace, SpaceInfo } from "./calendar.data";
import { AccountInfo } from "@azure/msal-browser";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { CalendarHelper } from "./calendar.helper";
import { of } from "rxjs";
import { CALogger } from "src/app/lib/logger";
import { EventEmitter } from "@angular/core";

export class CalendarWorkplaceServiceNow implements ICalendarWorkplace {
    readonly TAG: string = 'svcnow';
    private readonly PLUGIN_SERVER_BASE_URL: string = 'https://servicenow.plugins.oniadea.com';
    token: string;

    private _account: AccountInfo;
    get account(): AccountInfo {
        return this._account;
    }
    private _resourceAccount: { username: string, name: string, id?: string };
    get resourceAccount(): { username: string, name: string, id?: string } {
        return { username: this._account?.space?.spaceID, name: this._account?.space?.spaceName };
    }
    set resourceAccount(account: { username: string, name: string, id?: string }) {
        this._resourceAccount = account;
    }

    onActiveAccountChanged = new EventEmitter<{ name: string; username: string; }>();

    constructor(private http: HttpClient) {}

    getAccount(deviceIdentityId: string): Promise<{ isFault: boolean; data?: any; error?: string | number; errorMessage?: string; }> {
        return this.http.get(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/config/device/${deviceIdentityId}`, this.getPluginRequestHeaders({ tokenRequired: false })).pipe(
            map((res: { error: string | number, errorMessage: string, data?: any }) => {
                if (!res.error) {
                    this._account = {
                        tenantId: res.data.tenantUrl,
                        username: res.data.space.spaceID,
                        localAccountId: res.data.username,
                        homeAccountId: res.data.username,
                        environment: res.data.registerAccountID,
                        idToken: res.data.token,
                        other: { deviceID: deviceIdentityId },
                        space: res.data.space
                    };
                }

                return { isFault: res.error !== 0, data: res.data, error: res.error, errorMessage: res.errorMessage };
            })
        ).toPromise();
    }

    getRooms(): Promise<{ isFault: boolean, data?: any[], error?: string | number, errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    async getCalendarByDate(d: Date, force: boolean = false): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string; }> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        return this.http.get(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}?force=${force}`, this.getPluginRequestHeaders()).pipe(
            map((res: { error: string | number, data?: { calendar: any[], space: any, lastConfigUpdateTime: number }, errorMessage?: string }) => {
                if (res.error !== 0) {
                    return {
                        isFault: true,
                        error: res.error,
                        errorMessage: res.errorMessage
                    };
                }

                // update space if the panel is re-paired to another space; this will affect the space name on the panel
                if (res.data?.space && this._account.space?.spaceID !== res.data.space.spaceID) {
                    this._account.space = {
                        capacity: parseInt(res.data.space.capacity),
                        emailAddress: res.data.space.emailAddress,
                        spaceID: res.data.space.spaceID,
                        spaceName: res.data.space.spaceName,
                        spaceQRCodeLink: res.data.space.spaceQRCodeLink,
                        type: res.data.space.type
                    };

                    this.onActiveAccountChanged.emit();
                }

                return {
                    isFault: false,
                    data: {
                        calendar: res.data?.calendar?.map(d => new IAEventInfo(d)),
                        space: new SpaceInfo(res.data?.space),
                        lastConfigUpdateTime: res.data?.lastConfigUpdateTime
                    }
                }
            })
        ).toPromise();
    }

    async addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        CALogger.logInfo(this.TAG, `create adhoc event ${subject} between ${startDate} - ${endDate}`);

        return this.http.post(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}/add`, {
            startDate: this.getUTCDateString(startDate),
            endDate: this.getUTCDateString(endDate),
            subject: subject,
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: string | number, errorMessage?: string, data?: any }) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    async extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        let endDate: Date = event.endDate;
        endDate.setMinutes(endDate.getMinutes() + durationInMinute);
        CALogger.logInfo(this.TAG, `extend event with end time: ${endDate}`);

        return this.http.patch(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}/update/${event.id}`, {
            startDate: this.getUTCDateString(event.startDate),
            endDate: this.getUTCDateString(endDate)
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: string | number, errorMessage?: string, data?: any }) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    async stopEvent(event: IAEventInfo, stopDateTime?: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string; }> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }
        
        const endDate: Date = stopDateTime;
        endDate.setSeconds(0, 0);
        CALogger.logInfo(this.TAG, `stop event at ${endDate}`);

        return this.http.patch(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}/update/${event.id}`, {
            startDate: this.getUTCDateString(event.startDate),
            endDate: this.getUTCDateString(endDate)
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any }) => {
                return {
                    isFault: res.error !== 0,
                    data: endDate,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    async cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        CALogger.logInfo(this.TAG, 'cancel event: ', event);
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        return this.http.patch(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}/cancel/${event.id}`, {
            comment: comment
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any }) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    async checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        CALogger.logInfo(this.TAG, 'check in event: ', event);
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        let startDateStr: string = this.getUTCDateString(event.startDate);
        if (Date.now() < event.startDate.getTime()) {
            startDateStr = this.getUTCDateString(new Date());
        }

        return this.http.patch(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}/checkin/${event.id}`, {
            startDate: startDateStr,
            endDate: this.getUTCDateString(event.endDate)
        }, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any }) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    async checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        CALogger.logInfo(this.TAG, 'check out event: ', event);
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        return this.http.patch(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/calendar/${this._account.other.deviceID}/checkout/${event.id}`, {}, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, errorMessage?: string, data?: any }) => {
                return {
                    isFault: res.error !== 0,
                    error: res.error,
                    errorMessage: res.errorMessage
                }
            })
        ).toPromise();
    }

    async getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string; }> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        return await this.http.get(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/config/device/${this._account.other.deviceID}`, this.getPluginRequestHeaders()).pipe(
            map((res: { error: number, data?: any }) => {
                const config: IAConfig = new IAConfig(res.data.calendar);
                return { isFault: false, data: config };
            }),
            catchError((err) => {
                CALogger.logError(this.TAG, 'parse config failed. ex: ', err);
                return of({ isFault: true })
            })
        ).toPromise();
    }

    async getBlobFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob; mimeType?: string; }, error?: string | number, errorMessage?: string; }> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        return await this.http.get(`${this.PLUGIN_SERVER_BASE_URL}/workplace/svcnow/config/device/${this._account.other.deviceID}/image/${this._account.environment}/${relPath}`, this.getPluginRequestHeaders({ responseType: 'blob' })).pipe(
            map((res: Blob) => ({ isFault: false, data: { content: res, mimeType: res.type } }))
        ).toPromise();
    }

    async getTextFile(relPath: string): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string }> {
        return { isFault: true, errorMessage: 'Not support' };
    }

    isUnpaired(errorCode: string | number): boolean {
        return errorCode === 'E_NOT_PAIR';
    }

    async getQRCodeLink(options?: { title: string, lang: string }): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string}> {
        if (!this._account) {
            return { isFault: true, errorMessage: 'Account is NULL' };
        }

        return { isFault: false, data: this._account.space?.spaceQRCodeLink };
    }

    private getUTCDateString(d: Date): string {
        return `${d.getUTCFullYear()}-${CalendarHelper.padTime(d.getUTCMonth() + 1)}-${CalendarHelper.padTime(d.getUTCDate())} ${CalendarHelper.padTime(d.getUTCHours())}:${CalendarHelper.padTime(d.getUTCMinutes())}:00`;
    }

    private getPluginRequestHeaders(options?: { responseType?: string, tokenRequired?: boolean }): { headers: HttpHeaders, responseType?: any } {
        options = options || {};
        options.responseType = options.responseType || 'json';
        options.tokenRequired = options.tokenRequired === undefined ? true : options.tokenRequired;

        let headers: HttpHeaders = new HttpHeaders({});
        headers = headers.append('IAdea-Source-App', 'Booking-for-Workplace');
        if (this.token && options.tokenRequired) {
            headers = headers.append('Authorization', 'Bearer ' + this.token);
        }

        return { headers: headers, responseType: options.responseType };
    }
}