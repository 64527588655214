<div *ngIf="_badgeID" class="badgeHint">
    {{ _badgeCertifiedCountdownStr }}
</div>

<button #btnDetectionBegin type="button" class="d-none" data-bs-toggle="modal"
    data-bs-target="#dlgBadgeVerification">
</button>
<div #badgeVerifyModal id="dlgBadgeVerification" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
    role="dialog">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body badge-body pb-4" [style.background]="BADGE_BG_COLOR">
                <fa-icon [icon]="ICON_XMARK" class="close me-2" data-bs-dismiss="modal" aria-label="Close"
                    (click)="cancelBadgeDetection()"></fa-icon>
                <img src="{{_badgeImgUrl}}" class="badge-img" />
                <div class="badge-hint my-2">{{ 'lang.clause.swipeBadge' | translate }}</div>
                <h3 *ngIf="_isDetected" class="text-center my-4 txt-access" [class.verified]="_badgeID">
                    {{ (_badgeID ? 'lang.word.accessGrant' : 'lang.word.accessDeny') | translate }}
                </h3>
                <small class="text-countdown mt-4">{{ _badgeDetectionCountdownStr }}</small>
            </div>
            <button #btnDetectionEnd type="button" class="d-none" data-bs-dismiss="modal"></button>
        </div>
    </div>
</div>