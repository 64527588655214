import { APIBaseManager, API_METHOD_GET, API_METHOD_POST } from "../api.base";
import { HttpClient } from "@angular/common/http";


export class APIGetDeviceNetInfoManager extends APIBaseManager<void, void, void, any> {
    constructor(protected http: HttpClient) {
        super(http);

        this._name = 'GetDeviceNetInfo';
        this._method = API_METHOD_POST;
    }

    protected getRequestURL(host: string, path: void, query: void): string {
        return super.getRequestURL(host, path, query) + 'android.net.ConnectivityManager/getAllNetworkInterfaces';
    }
}