<div class="calendar-content-wrapper position-relative">
    <div class="calendar-header">
        <h1 class="d-flex align-items-center">
            <div *ngIf="logo" class="logo me-4" [ngStyle]="{ 'background-image': 'url(' + logo + ')'}">
            </div>
            <div>{{ account?.name || account?.username }}</div>
        </h1>
        <div>
            <div class="date">
                {{ date | pipeDate }}
            </div>
            <div class="d-flex align-items-center">
                <div class="time">
                    {{ date | pipeTime }}
                </div>
                <!--
                <div class="ms-auto">
                    <ia-sensor-co2></ia-sensor-co2>
                </div>
                -->
            </div>
        </div>
    </div>
    <div class="calendar-content" [class.overlay]="!isOnline"
        [style.background]="currentEvent ? CONFIG_BUSY_BG_COLOR : CONFIG_VANCANCY_BG_COLOR">
        <div class="subject">
            {{ (currentEvent?.subject || 'lang.word.available' | translate) | pipeTextOverflow: _textOverflowLength }}
        </div>
        <div class="desc">
            <div *ngIf="currentEvent; else tempNoCurrent">
                <span *ngIf="!currentEvent.isAllDay; else tempAllDayTime">
                    {{ currentEvent?.startDate | pipeTime }} - {{ currentEvent?.endDate | pipeTime }}
                </span>
                <ng-template #tempAllDayTime>
                    {{ 'lang.word.allDay' | translate }}
                </ng-template>
            </div>
            <ng-template #tempNoCurrent>
                <div *ngIf="nextEvent; else tempNoNext">
                    {{ 'lang.clause.freeUntil' | translate: _freeUntilParams }}
                </div>
                <ng-template #tempNoNext>{{ 'lang.clause.freeAllDay' | translate }}</ng-template>
            </ng-template>
        </div>
        <div>
            <div class="control-block">
                <ng-container *ngIf="currentEvent; else templateNoCurrentEvent">
                    <ng-container *ngIf="space.checkinRequired; else templateNoNeedCheckin">
                        <ng-container *ngIf="!currentEvent.isCheckin; else templateAlreadyCheckin">
                            <button type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                                (click)="checkinEvent()">
                                <fa-icon [icon]="ICON_CALENDAR_CHECKIN"></fa-icon>
                                {{ 'lang.word.checkin' | translate }}
                            </button>
                            <button type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                                (click)="cancelEvent()">
                                <fa-icon [icon]="ICON_FATIMES"></fa-icon>
                                {{ 'lang.word.cancel' | translate }}
                            </button>
                        </ng-container>
                        <ng-template #templateAlreadyCheckin>
                            <button *ngIf="!currentEvent.isAllDay" id="btnExtend" type="button"
                                class="btn btn-outline-dark" data-bs-toggle="collapse" data-bs-target="#collapseExtend"
                                aria-expanded="false" [disabled]="isUpdating || !license" (click)="expandEvent()">
                                <fa-icon [icon]="ICON_FAPLUS"></fa-icon>
                                {{ 'lang.word.extend' | translate }}
                            </button>
                            <button *ngIf="allowEventEnd" type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                                (click)="stopEvent()">
                                <fa-icon [icon]="ICON_FATIMES"></fa-icon>
                                {{ 'lang.word.end' | translate }}
                            </button>
                            <button type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                                (click)="checkoutEvent()">
                                <fa-icon [icon]="ICON_CALENDAR_CHECKOUT"></fa-icon>
                                {{ 'lang.word.checkout' | translate }}
                            </button>
                        </ng-template>
                    </ng-container>
                    <ng-template #templateNoNeedCheckin>
                        <button *ngIf="!currentEvent.isAllDay" id="btnExtend" type="button" class="btn btn-outline-dark"
                            data-bs-toggle="collapse" data-bs-target="#collapseExtend" aria-expanded="false"
                            [disabled]="isUpdating || !license" (click)="expandEvent()">
                            <fa-icon [icon]="ICON_FAPLUS"></fa-icon>
                            {{ 'lang.word.extend' | translate }}
                        </button>
                        <ng-container *ngIf="allowEventEnd; else templateCancelEvent">
                            <button type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                                (click)="stopEvent()">
                                <fa-icon [icon]="ICON_FATIMES"></fa-icon>
                                {{ 'lang.word.end' | translate }}
                            </button>
                        </ng-container>
                        <ng-template #templateCancelEvent>
                            <button type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                                (click)="cancelEvent()">
                                <fa-icon [icon]="ICON_FATIMES"></fa-icon>
                                {{ 'lang.word.cancel' | translate }}
                            </button>
                        </ng-template>
                    </ng-template>
                </ng-container>
                <ng-template #templateNoCurrentEvent>
                    <ng-container *ngIf="isCheckinRequired(); else templateMeetNow">
                        <button type="button" class="btn btn-outline-dark" [disabled]="isUpdating || !license"
                            (click)="checkinEvent()">
                            <fa-icon [icon]="ICON_CALENDAR_CHECKIN"></fa-icon>
                            {{ 'lang.word.checkin' | translate }}
                        </button>
                    </ng-container>
                    <ng-template #templateMeetNow>
                        <button *ngIf="CONFIG_ENABLE_ONSITE_BOOK" type="button" class="btn btn-outline-dark"
                            [disabled]="isUpdating || !license" (click)="addEvent()">
                            <fa-icon [icon]="ICON_CALENDAR_PLUS" class="me-1"></fa-icon>
                            {{ 'lang.word.meetNow' | translate }}
                        </button>
                    </ng-template>
                </ng-template>
            </div>
            <div>
                <div id="collapseExtend" class="accordion-collapse collapse" aria-labelledby="btnExtend">
                    <div class="accordion-body">
                        <div *ngIf="_availableEventIntervals.length > 0; else tempNoUsefulDurations">
                            <span>
                                <span *ngFor="let duration of _availableEventIntervals" class="duration"
                                    data-bs-toggle="collapse" data-bs-target="#collapseExtend"
                                    (click)="extendEvent(duration)">
                                    {{ duration }}
                                </span>
                            </span>
                            <span class="ms-2">({{ 'lang.word.minute' | translate }})</span>
                        </div>
                        <ng-template #tempNoUsefulDurations>
                            {{ 'lang.clause.insufficientTime' | translate }}
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="float-icon float-icon-rt">
            <button type="button" class="btn-none" [disabled]="!license" (click)="refresh()">
                <fa-icon [icon]="ICON_SYNC" class="icon" [class.rotate]="isUpdating"></fa-icon>
            </button>
        </div>
    </div>
    <div class="sensor-people">
        <ia-sensor-people [currentEvent]="_currentEvent" [occupancy]="space.occupancy"></ia-sensor-people>
    </div>
</div>