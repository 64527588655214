/***************************************************
 * dateTimeOption:
 * {
 *     weekday: 'narrow' | 'short' | 'long',
 *     era: 'narrow' | 'short' | 'long',
 *     year: 'numeric' | '2-digit',
 *     month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
 *     day: 'numeric' | '2-digit',
 *     hour: 'numeric' | '2-digit',
 *     minute: 'numeric' | '2-digit',
 *     second: 'numeric' | '2-digit',
 *     timeZoneName: 'short' | 'long',
 * 
 *     // Time zone to express it in
 *     timeZone: 'Asia/Shanghai',
 *     // Force 12-hour or 24-hour
 *     hour12: true | false,
 * 
 *     // Rarely-used options
 *     hourCycle: 'h11' | 'h12' | 'h23' | 'h24', //not appear in es2015 interface
 *     formatMatcher: 'basic' | 'best fit'
 * }
 ******************************************************/

import { CalendarScope, UITemplate } from "./calendar/lib/calendar.data";
import { ColorPickerMode } from "./ui-comp/ia-color.data";

export interface IMerge<T> {
    merge(data: T): T;
}

export interface IConfig {
    locale?: string; //en-US
    pin?: number;
    dateTimeOption?: Intl.DateTimeFormatOptions;
    background?: string;
    logo?: string;
    fontsizeRatio?: number;
    theme?: {
        foreground?: string;
        availableColor?: string;
        busyColor?: string;
        timeline?: {
            bgColor?: string;
            futureEventTimeBlockColor?: string;
            currentEventTimeBlockColor?: string;
            expiredEventTimeBlockColor?: string;
        }
    };
    lightbar?: {
        activate?: boolean;
        available?: {
            mode?: string;
            color?: string;
        };
        busy?: {
            mode?: string;
            color?: string;
        }
    };
    calendar?: {
        enableOnsiteBook?: boolean;
        enableOnsiteExtend?: boolean;
        enableOnsiteCancelOrStop?: boolean;
        enableFutureEventBook?: boolean;
        enableFutureEventCancel?: boolean;
        enableDateSwitch?: boolean;
        hideTimeline?: boolean;
        hideHeader?: boolean;
        showUnBusyEvent?: boolean;
        showQRCodeAlways?: boolean;
        showCancelledEvent?: boolean;
        msgPopupDuration?: number;
        minEventDuration?: number;
        timelineIdleDuration?: number;
        alertLimit?: number;
    };
    resource?: {
        bg?: {
            sizeLimit?: number;
            supportMimeTypes?: string[];
        },
        logo?: {
            sizeLimit?: number;
            supportMimeTypes?: string[];
        }
    };
    badge?: {
        enableBadgeDetection: boolean;
        bg: string;
    };
}

export class IAngEnvironmentConfig {
    version: string;
    name: string;
    title: string;
    production: boolean;
    scope: CalendarScope;
    template: UITemplate;
    supportLogout: boolean;
    license: {
        trial: boolean;
        mode: 'idToken' | 'signature' | 'none';
        server: string;
        statement?: string;
    };
    auth: {
        tenantId: string;
        appId: string;
        redirectUri: string;
    };
    resource: {
        logo: string;
        bg: string;
        badge: string;
    };
    system: {
        lockByIAdea: boolean;
        colorPicker: ColorPickerMode;
        configRootFolder: string;
        configFilename: string;
    };
    config: IConfig; // editable by customer
}

export class IAConfig implements IConfig, IMerge<IAConfig> {
    locale?: string; //en-US
    pin?: number;
    dateTimeOption?: Intl.DateTimeFormatOptions;
    background?: string;
    logo?: string;
    fontsizeRatio?: number;
    theme?: {
        foreground?: string;
        availableColor?: string;
        busyColor?: string;
        timeline?: {
            bgColor?: string;
            futureEventTimeBlockColor?: string;
            currentEventTimeBlockColor?: string;
            expiredEventTimeBlockColor?: string;
        }
    };
    lightbar?: {
        activate?: boolean;
        available?: {
            mode?: string;
            color?: string;
        };
        busy?: {
            mode?: string;
            color?: string;
        }
    };
    calendar?: {
        enableOnsiteBook?: boolean;
        enableOnsiteExtend?: boolean;
        enableOnsiteCancelOrStop?: boolean;
        enableFutureEventBook?: boolean;
        enableFutureEventCancel?: boolean;
        enableDateSwitch?: boolean;
        hideTimeline?: boolean;
        hideHeader?: boolean;
        showUnBusyEvent?: boolean;
        showQRCodeAlways?: boolean;
        showCancelledEvent?: boolean;
        msgPopupDuration?: number;
        minEventDuration?: number;
        timelineIdleDuration?: number;
        alertLimit?: number;
    };
    resource?: {
        bg?: {
            sizeLimit?: number;
            supportMimeTypes?: string[];
        },
        logo?: {
            sizeLimit?: number;
            supportMimeTypes?: string[];
        }
    };
    badge?: {
        enableBadgeDetection: boolean;
        bg: string;
    };

    constructor(raw?: IAConfig) {
        this.locale = '';
        this.pin = 1688;
        this.fontsizeRatio = 1;
        this.dateTimeOption = {};
        this.background = '';
        this.logo = '';
        this.theme = { timeline: {} };
        this.calendar = {};
        this.lightbar = { activate: true, available: {}, busy: {} };
        this.resource = { bg: {}, logo: {} };
        this.badge = { enableBadgeDetection: false, bg: '' };

        if (raw) {
            this.merge(raw);
        }
    }

    isUnset(): boolean {
        return this.locale === '';
    }

    merge(newConfig: IAConfig, isLocalOnlyConfig: boolean = false): IAConfig {
        if (!newConfig) {
            return this;
        }

        // global & local
        this.locale = newConfig.locale || this.locale;
        this.fontsizeRatio = newConfig.fontsizeRatio ?? this.fontsizeRatio;
        if (newConfig.dateTimeOption) {
            if (!this.dateTimeOption) {
                this.dateTimeOption = newConfig.dateTimeOption;
            }
            else {
                this.dateTimeOption.hour12 = newConfig.dateTimeOption.hour12 ?? this.dateTimeOption.hour12;
            }
        }
        if (newConfig.theme) {
            if (!this.theme) {
                this.theme = newConfig.theme;
            }
            else {
                this.theme.foreground = newConfig.theme.foreground || this.theme.foreground;
                this.theme.availableColor = newConfig.theme.availableColor || this.theme.availableColor;
                this.theme.busyColor = newConfig.theme.busyColor || this.theme.busyColor;

                if (newConfig.theme.timeline) {
                    if (!this.theme.timeline) {
                        this.theme.timeline = newConfig.theme.timeline;
                    }
                    else {
                        this.theme.timeline.bgColor = newConfig.theme.timeline.bgColor || this.theme.timeline.bgColor;
                        this.theme.timeline.currentEventTimeBlockColor = newConfig.theme.timeline.currentEventTimeBlockColor || this.theme.timeline.currentEventTimeBlockColor;
                        this.theme.timeline.expiredEventTimeBlockColor = newConfig.theme.timeline.expiredEventTimeBlockColor || this.theme.timeline.expiredEventTimeBlockColor;
                        this.theme.timeline.futureEventTimeBlockColor = newConfig.theme.timeline.futureEventTimeBlockColor || this.theme.timeline.futureEventTimeBlockColor;
                    }
                }
            }
        }

        if (newConfig.lightbar) {
            if (newConfig.lightbar.activate === undefined) {
                newConfig.lightbar.activate = true;
            }

            if (!this.lightbar) {
                this.lightbar = newConfig.lightbar;
            }
            else {
                this.lightbar.activate = newConfig.lightbar.activate;
                if (newConfig.lightbar.available) {
                    if (!this.lightbar.available) {
                        this.lightbar.available = newConfig.lightbar.available;
                    }
                    else {
                        this.lightbar.available.color = newConfig.lightbar.available.color || this.lightbar.available.color;
                        this.lightbar.available.mode = newConfig.lightbar.available.mode || this.lightbar.available.mode;
                    }
                }
                if (newConfig.lightbar.busy) {
                    if (!this.lightbar.busy) {
                        this.lightbar.busy = newConfig.lightbar.busy;
                    }
                    else {
                        this.lightbar.busy.color = newConfig.lightbar.busy.color || this.lightbar.busy.color;
                        this.lightbar.busy.mode = newConfig.lightbar.busy.mode || this.lightbar.busy.mode;
                    }
                }
            }
        }

        // global only configs
        if (!isLocalOnlyConfig) {
            this.pin = newConfig.pin || this.pin;
            this.background = newConfig.background || this.background;
            this.logo = newConfig.logo || this.logo;

            if (newConfig.dateTimeOption) {
                if (!this.dateTimeOption) {
                    this.dateTimeOption = newConfig.dateTimeOption;
                }
                else {
                    this.dateTimeOption.year = newConfig.dateTimeOption.year || this.dateTimeOption.year;
                    this.dateTimeOption.month = newConfig.dateTimeOption.month || this.dateTimeOption.month;
                    this.dateTimeOption.day = newConfig.dateTimeOption.day || this.dateTimeOption.day;
                    this.dateTimeOption.weekday = newConfig.dateTimeOption.weekday || this.dateTimeOption.weekday;
                    this.dateTimeOption.hour = newConfig.dateTimeOption.hour || this.dateTimeOption.hour;
                    this.dateTimeOption.minute = newConfig.dateTimeOption.minute || this.dateTimeOption.minute;
                    this.dateTimeOption.hour12 = newConfig.dateTimeOption.hour12 ?? this.dateTimeOption.hour12;
                }
            }

            if (newConfig.calendar) {
                if (!this.calendar) {
                    this.calendar = newConfig.calendar;
                }
                else {
                    this.calendar.enableFutureEventBook = newConfig.calendar.enableFutureEventBook ?? this.calendar.enableFutureEventBook;
                    this.calendar.enableFutureEventCancel = newConfig.calendar.enableFutureEventCancel ?? this.calendar.enableFutureEventCancel;
                    this.calendar.enableOnsiteBook = newConfig.calendar.enableOnsiteBook ?? this.calendar.enableOnsiteBook;
                    this.calendar.enableOnsiteExtend = newConfig.calendar.enableOnsiteExtend ?? this.calendar.enableOnsiteExtend;
                    this.calendar.enableOnsiteCancelOrStop = newConfig.calendar.enableOnsiteCancelOrStop ?? this.calendar.enableOnsiteCancelOrStop;
                    this.calendar.enableDateSwitch = newConfig.calendar.enableDateSwitch ?? this.calendar.enableDateSwitch;
                    this.calendar.hideTimeline = newConfig.calendar.hideTimeline ?? this.calendar.hideTimeline;
                    this.calendar.hideHeader = newConfig.calendar.hideHeader ?? this.calendar.hideHeader;
                    this.calendar.showUnBusyEvent = newConfig.calendar.showUnBusyEvent ?? this.calendar.showUnBusyEvent;
                    this.calendar.showQRCodeAlways = newConfig.calendar.showQRCodeAlways ?? this.calendar.showQRCodeAlways;
                    this.calendar.showCancelledEvent = newConfig.calendar.showCancelledEvent ?? this.calendar.showCancelledEvent;
                    this.calendar.msgPopupDuration = newConfig.calendar.msgPopupDuration || this.calendar.msgPopupDuration;
                    this.calendar.minEventDuration = newConfig.calendar.minEventDuration || this.calendar.minEventDuration;
                    this.calendar.timelineIdleDuration = newConfig.calendar.timelineIdleDuration || this.calendar.timelineIdleDuration;
                    this.calendar.alertLimit = newConfig.calendar.alertLimit || this.calendar.alertLimit;
                }
            }

            if (newConfig.resource) {
                if (!this.resource) {
                    this.resource = newConfig.resource;
                }
                else {
                    if (newConfig.resource.bg) {
                        if (!this.resource.bg) {
                            this.resource.bg = newConfig.resource.bg;
                        }
                        else {
                            this.resource.bg.supportMimeTypes = newConfig.resource.bg.supportMimeTypes ?? this.resource.bg.supportMimeTypes;
                            this.resource.bg.sizeLimit = newConfig.resource.bg.sizeLimit || this.resource.bg.sizeLimit;
                        }
                    }

                    if (newConfig.resource.logo) {
                        if (!this.resource.logo) {
                            this.resource.logo = newConfig.resource.logo;
                        }
                        else {
                            this.resource.logo.supportMimeTypes = newConfig.resource.logo.supportMimeTypes ?? this.resource.logo.supportMimeTypes;
                            this.resource.logo.sizeLimit = newConfig.resource.logo.sizeLimit || this.resource.logo.sizeLimit;
                        }
                    }
                }
            }

            if (newConfig.badge) {
                if (!this.badge) {
                    this.badge = newConfig.badge;
                }
                else {
                    this.badge.enableBadgeDetection = newConfig.badge.enableBadgeDetection ?? this.badge.enableBadgeDetection;
                    this.badge.bg = newConfig.badge.bg || this.badge.bg;
                }
            }
        }

        return this;
    }
}

export class IALocalConfig extends IAConfig {
    merge(newConfig: IALocalConfig): IALocalConfig {
        if (!newConfig) {
            return this;
        }

        //only merge required properties
        this.locale = newConfig.locale || this.locale;
        this.fontsizeRatio = newConfig.fontsizeRatio || this.fontsizeRatio;
        if (newConfig.dateTimeOption) {
            if (!this.dateTimeOption) {
                this.dateTimeOption = newConfig.dateTimeOption;
            }
            else {
                this.dateTimeOption.hour12 = newConfig.dateTimeOption.hour12 ?? this.dateTimeOption.hour12;
            }
        }
        if (newConfig.lightbar) {
            if (!this.lightbar) {
                this.lightbar = newConfig.lightbar;
            }
            else {
                this.lightbar.activate = newConfig.lightbar.activate;
                if (newConfig.lightbar.available) {
                    if (!this.lightbar.available) {
                        this.lightbar.available = newConfig.lightbar.available;
                    }
                    else {
                        this.lightbar.available.color = newConfig.lightbar.available.color || this.lightbar.available.color;
                        this.lightbar.available.mode = newConfig.lightbar.available.mode || this.lightbar.available.mode;
                    }
                }
                if (newConfig.lightbar.busy) {
                    if (!this.lightbar.busy) {
                        this.lightbar.busy = newConfig.lightbar.busy;
                    }
                    else {
                        this.lightbar.busy.color = newConfig.lightbar.busy.color || this.lightbar.busy.color;
                        this.lightbar.busy.mode = newConfig.lightbar.busy.mode || this.lightbar.busy.mode;
                    }
                }
            }
        }

        if (newConfig.theme) {
            if (!this.theme) {
                this.theme = newConfig.theme;
            }
            else {
                this.theme.foreground = newConfig.theme.foreground || this.theme.foreground;
                this.theme.availableColor = newConfig.theme.availableColor || this.theme.availableColor;
                this.theme.busyColor = newConfig.theme.busyColor || this.theme.busyColor;

                if (newConfig.theme.timeline) {
                    if (!this.theme.timeline) {
                        this.theme.timeline = newConfig.theme.timeline;
                    }
                    else {
                        this.theme.timeline.bgColor = newConfig.theme.timeline.bgColor || this.theme.timeline.bgColor;
                        this.theme.timeline.currentEventTimeBlockColor = newConfig.theme.timeline.currentEventTimeBlockColor || this.theme.timeline.currentEventTimeBlockColor;
                        this.theme.timeline.expiredEventTimeBlockColor = newConfig.theme.timeline.expiredEventTimeBlockColor || this.theme.timeline.expiredEventTimeBlockColor;
                        this.theme.timeline.futureEventTimeBlockColor = newConfig.theme.timeline.futureEventTimeBlockColor || this.theme.timeline.futureEventTimeBlockColor;
                    }
                }
            }
        }

        return this;
    }
}