
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, interval, Subject, timer } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAConfig } from '../../../app-config.model';
import { CalendarAction, IAEventInfo, ITimelineBusyEvent, ITimelineData, Orientation, SpaceInfo } from '../../lib/calendar.data';
import { CalendarHelper } from '../../lib/calendar.helper';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight, faArrowAltCircleLeft, faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-regular-svg-icons';
import { AppConfigService } from '../../../app-config.service';
import { Helper } from '../../../lib/helper';
import { LicenseInfo } from 'src/app/lib/iadea/license/license.data';

@Component({
    selector: 'ca-timeline',
    templateUrl: './calendar-timeline.component.html',
    styleUrls: [
        './calendar-timeline.component.css'
    ]
})
export class CalendarTimelineComponent implements OnInit, AfterViewInit, OnDestroy {
    //icons
    readonly ICON_FATIMES = faTimes;
    readonly ICON_ARROW_RIGHT = faArrowAltCircleRight;
    readonly ICON_ARROW_LEFT = faArrowAltCircleLeft;
    readonly ICON_CARET_LEFT = faCaretSquareLeft;
    readonly ICON_CARET_RIGHT = faCaretSquareRight;
    //constants
    readonly SLOT_DURATION: number = 15;
    readonly SLOT_HEIGHT: number = 4.5; //rem
    readonly SLOT_RATIO: number = this.SLOT_HEIGHT / this.SLOT_DURATION;

    //config options
    CONFIG_TIMELINE_BLOCK_CURRENT_BG_COLOR: string = Helper.hexToRgba(AppConfigService.config.theme.timeline.currentEventTimeBlockColor);
    CONFIG_TIMELINE_BLOCK_FUTURE_BG_COLOR: string = Helper.hexToRgba(AppConfigService.config.theme.timeline.futureEventTimeBlockColor);
    CONFIG_TIMELINE_BLOCK_EXPIRE_BG_COLOR: string = Helper.hexToRgba(AppConfigService.config.theme.timeline.expiredEventTimeBlockColor);
    CONFIG_TIMELINE_BG_COLOR: string = AppConfigService.config.theme.timeline.bgColor;
    CONFIG_ENABLE_FUTURE_EVENT_BOOK: boolean = AppConfigService.config.calendar.enableFutureEventBook;
    CONFIG_ENABLE_FUTURE_EVENT_CANCEL: boolean = AppConfigService.config.calendar.enableFutureEventCancel;
    CONFIG_ENABLE_ADJUST_DATE: boolean = AppConfigService.config.calendar.enableDateSwitch;

    _timelineSlotList: ITimelineData[] = [];
    _expand: boolean = false;
    _timeIndicatorOffset: number = -1;
    _busyList: ITimelineBusyEvent[] = [];
    _highlightBusyEvent: ITimelineBusyEvent;
    _textOverflowLength: number = 12;

    private _scroller$: Subject<number> = new Subject();
    private _idleTimer$: Subject<void> = new Subject();
    private readonly _unsubscribe$: Subject<void> = new Subject();;

    _calendar: IAEventInfo[];
    @Input('calendar')
    set calendar(v: IAEventInfo[]) {
        this._calendar = v;
        this.updateBusyTimeslot();
        this.updateScrollbarOffsetLocation(5000);
    }

    _current: IAEventInfo;
    @Input('currentEvent')
    set current(v: IAEventInfo) {
        this._current = v;
    }

    _isOnline: boolean = true;
    @Input('isOnline')
    set isOnline(v: boolean) {
        this._isOnline = v;
    }

    _updating: boolean = true;
    @Input('updating')
    set updating(v: boolean) {
        this._updating = v;
    }

    @Input('license') _license: LicenseInfo;
    private _space: SpaceInfo;
    @Input('space')
    set space(v: SpaceInfo) {
        this._space = v;
    }

    _enumOrientation: typeof Orientation = Orientation;
    _orientation: Orientation = Orientation.Landscape;
    @Input('orientation')
    set orientation(v: Orientation) {
        if (this._orientation !== v) {
            this._orientation = v;
            this.updateScrollbarOffsetLocation(0);
            this.expandTimeline(false);
        }
    }

    _date: Date;
    @Input('date')
    set now(v: Date) {
        this._date = v;
        if (this._date?.getSeconds() === 0) {
            this.updateTimeIndicatorLocation();
            this.updateBusyTimeslotStatus();
        }
    }

    private _config: IAConfig;
    @Input('config')
    set config(v: IAConfig) {
        this._config = v;
        if (this._config) {
            this.CONFIG_ENABLE_FUTURE_EVENT_BOOK = this._config.calendar.enableFutureEventBook;
            this.CONFIG_ENABLE_FUTURE_EVENT_CANCEL = this._config.calendar.enableFutureEventCancel;
            this.CONFIG_ENABLE_ADJUST_DATE = this._config.calendar.enableDateSwitch;
            this.CONFIG_TIMELINE_BLOCK_CURRENT_BG_COLOR = Helper.hexToRgba(this._config.theme.timeline.currentEventTimeBlockColor);
            this.CONFIG_TIMELINE_BLOCK_FUTURE_BG_COLOR = Helper.hexToRgba(this._config.theme.timeline.futureEventTimeBlockColor);
            this.CONFIG_TIMELINE_BLOCK_EXPIRE_BG_COLOR = Helper.hexToRgba(this._config.theme.timeline.expiredEventTimeBlockColor);
            this.CONFIG_TIMELINE_BG_COLOR = Helper.hexToRgba(this._config.theme.timeline.bgColor);
        }
    }

    @Output() expanded = new EventEmitter<boolean>();
    @Output() onAction = new EventEmitter<{ action: CalendarAction, data?: any }>();

    private _timelineContainerRef: ElementRef;
    @ViewChild('timelineContainer', { static: true })
    set timelineContainer(v: ElementRef) {
        this._timelineContainerRef = v;
        if (this._timelineContainerRef) {
            const mouseDown$ = fromEvent(this._timelineContainerRef.nativeElement, 'mousedown');
            mouseDown$.pipe(
                debounceTime(3000),
                takeUntil(this._unsubscribe$)
            ).subscribe(() => {
                this._idleTimer$.next();
            });
        }
    }

    private _timelineScrollerEleRef: ElementRef;
    @ViewChild('timelineScroller', { static: true })
    set timelineScroller(v: ElementRef) {
        this._timelineScrollerEleRef = v;
    }

    ngOnInit(): void {
        this.initTimeline();
        this.updateTimeIndicatorLocation();
        this.initTimelineIdleDetector();

        this._scroller$.pipe(
            switchMap((delay: number) => timer(delay)),
            takeUntil(this._unsubscribe$)
        ).subscribe(() => {
            //use directive?
            const offsetComplement: number = this._orientation === Orientation.Portrait ? 20 : 60;
            this._timelineScrollerEleRef.nativeElement.style.maxHeight = this._orientation === Orientation.Portrait ? 'calc(100vh - ' + (this._timelineContainerRef.nativeElement.offsetTop + offsetComplement) + 'px)' : '';

            const targetDate: Date = this._orientation === Orientation.Portrait && this._current && CalendarHelper.getEventDuration(this._current) < 120 ? this._current.startDate : this._date;
            const minute_ratio = (targetDate.getHours() * 60 + targetDate.getMinutes()) / 1440;

            const scrollTop: number = minute_ratio * this._timelineScrollerEleRef.nativeElement.scrollHeight - (this._orientation === Orientation.Landscape ? this._timelineScrollerEleRef.nativeElement.scrollWidth : 20);
            this._timelineScrollerEleRef.nativeElement.scrollTop = scrollTop;
        });
    }

    ngAfterViewInit(): void {
        this.updateScrollbarOffsetLocation(0);
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    expandTimeline(expand?: boolean): void {
        if (this._orientation === Orientation.Portrait) {
            this._expand = true;
            return;
        }

        this._expand = expand ?? !this._expand;
        if (!this._expand) {
            this.highlightEvent(null);
        }
        this.expanded.emit(this._expand);
    }

    highlightEvent(bs: ITimelineBusyEvent): void {
        this._highlightBusyEvent = this._highlightBusyEvent === bs ? null : bs;
    }

    addEvent(slot: ITimelineData): void {
        //not allowed or under updating
        if (this._updating || !this._license || !this.CONFIG_ENABLE_FUTURE_EVENT_BOOK) {
            return;
        }

        const d: Date = new Date(this._date);
        d.setHours(slot.hour);
        d.setMinutes(slot.minute, 0, 0);

        //expired timeslot
        if (d < new Date(this._date)) {
            return;
        }

        //conflict event
        if (this._current && CalendarHelper.isTimeInRange(d, this._current.startDate, this._current.endDate, false)) {
            return;
        }

        this.onAction.emit({ action: CalendarAction.AddEvent, data: { startDate: d } });
    }

    removeEvent(busyEvent: ITimelineBusyEvent): void {
        if (this._updating || !this._license || !this.CONFIG_ENABLE_FUTURE_EVENT_CANCEL) {
            return;
        }

        this.onAction.emit({ 
            action: this._space?.checkinRequired && busyEvent.source.isCheckin ? CalendarAction.Checkout : CalendarAction.CancelEvent, 
            data: busyEvent.source 
        });
    }

    trackBusyTimeslotFn(index: number, item: ITimelineBusyEvent): string {
        return item.source?.id;
    }

    viewPrevDate(): void {
        this.adjustDate(-1);
    }

    viewNextDate(): void {
        this.adjustDate(1);
    }

    private adjustDate(offsetDay: number): void {
        if (this._updating) {
            return;
        }

        this.onAction.emit({ action: CalendarAction.AdjustDate, data: { day: offsetDay } });
    }

    private updateBusyTimeslotStatus(): void {
        this._busyList.forEach(bs => {
            bs.isExpired = this._date > bs.source.endDate,
                bs.isInProcess = this._date >= bs.source.startDate && this._date <= bs.source.endDate
        });
    }

    private updateBusyTimeslot(): void {
        const busyList: ITimelineBusyEvent[] = this._calendar?.filter(ev => !ev.isAllDay || (ev.isAllDay && CalendarHelper.isAllDayContainsToday(ev))).map((ev: IAEventInfo) => {
            let begin: number = (ev.startDate.getHours() * 60 + ev.startDate.getMinutes()) * this.SLOT_RATIO;
            let end: number = (ev.endDate.getHours() * 60 + ev.endDate.getMinutes()) * this.SLOT_RATIO;
            //for cross-day event
            if (CalendarHelper.isCrossDay(ev.startDate, ev.endDate)) {
                if (CalendarHelper.isSameDay(ev.startDate, this._date)) {
                    end = 24 * 60 * this.SLOT_RATIO;
                }
                else {
                    begin = 0;
                }
            }

            if (ev.isAllDay) {
                begin = 0;
                end = 24 * 60 * this.SLOT_RATIO;
            }

            return {
                fakeID: ev.id.substring(ev.id.length - 9),
                subject: ev.subject,
                source: ev,
                begin: begin,
                end: end,
                isExpired: this._date > ev.endDate,
                isInProcess: this._date >= ev.startDate && this._date < ev.endDate,
                overlapOffsetLeft: 0
            };
        }).filter(busy => busy.end > 0);

        const minOverlapDuration: number = 5;
        //construct overlap map
        let max_overlap_count: number = 0;
        const overlap_record: { [hmID: string]: { [ptr: string]: string } } = {};

        for (const bs of busyList) {
            let ptr: number = 0;
            const dfloor: Date = CalendarHelper.getFloorDate(bs.source.startDate, minOverlapDuration, true);
            const dceil: Date = CalendarHelper.getCeilDate(bs.source.endDate, minOverlapDuration, true);
            //find correct ptr
            let hmID: string = CalendarHelper.padTime(dfloor.getHours()) + CalendarHelper.padTime(dfloor.getMinutes());
            if (overlap_record[hmID]) {
                ptr = Object.keys(overlap_record[hmID]).length;

                for (let i = 0; i < max_overlap_count; i++) {
                    if (!overlap_record[hmID][i]) {
                        ptr = i;
                        break;
                    }
                }
            }

            while (dfloor < dceil) {
                if (!overlap_record[hmID]) {
                    overlap_record[hmID] = {};

                    for (let i = 0; i < ptr; i++) {
                        overlap_record[hmID][i] = '';
                    }
                }

                overlap_record[hmID][ptr] = bs.fakeID;
                max_overlap_count = ptr + 1 > max_overlap_count ? ptr + 1 : max_overlap_count;

                dfloor.setMinutes(dfloor.getMinutes() + minOverlapDuration);
                hmID = CalendarHelper.padTime(dfloor.getHours()) + CalendarHelper.padTime(dfloor.getMinutes());
            }
        }

        for (const bs of busyList) {
            const dfloor: Date = CalendarHelper.getFloorDate(bs.source.startDate, minOverlapDuration, true);
            const dceil: Date = CalendarHelper.getCeilDate(bs.source.endDate, minOverlapDuration, true);
            let hmID: string = CalendarHelper.padTime(dfloor.getHours()) + CalendarHelper.padTime(dfloor.getMinutes());
            //use first record of event to decide the left offset.
            for (let i = 0; i < max_overlap_count; i++) {
                if (overlap_record[hmID]?.[i] === bs.fakeID) {
                    bs.overlapOffsetLeft = i;
                    break;
                }
            }
            //use the overlap count for a event in a hmID to decide the width
            let bsMaxOverlapCount: number = 0;
            while (dfloor < dceil && bsMaxOverlapCount < max_overlap_count) {
                const overlapCount: number = Object.keys(overlap_record[hmID]).length;
                if (overlapCount > bsMaxOverlapCount) {
                    bsMaxOverlapCount = overlapCount;
                }

                dfloor.setMinutes(dfloor.getMinutes() + minOverlapDuration);
                hmID = CalendarHelper.padTime(dfloor.getHours()) + CalendarHelper.padTime(dfloor.getMinutes());
            }

            bs.overlapWidth = 1 + (bs.overlapOffsetLeft < bsMaxOverlapCount - 1 ? 0 : max_overlap_count - bsMaxOverlapCount);
            bs.overlapRaio = 100 / max_overlap_count;
            bs.isOverlap = bsMaxOverlapCount === 1 ? false : true;
        }

        this._busyList = busyList;
    }

    private initTimeline(): void {
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += this.SLOT_DURATION) {
                this._timelineSlotList.push({
                    hour: i,
                    minute: j,
                    str: CalendarHelper.padTime(i) + ':' + CalendarHelper.padTime(j)
                });
            }
        }
    }

    private initTimelineIdleDetector(): void {
        Helper.waitUntil(() => { return this._config ? true : false }, 1000).subscribe(() => {
            this._idleTimer$.pipe(
                switchMap(() => interval(this._config.calendar.timelineIdleDuration * 60000)),
                map(() => {
                    this.expandTimeline(false);
                    this.updateScrollbarOffsetLocation(0);
                }),
                takeUntil(this._unsubscribe$)
            ).subscribe();

            this._idleTimer$.next();
        });
    }

    private updateTimeIndicatorLocation(): void {
        if (!this._date) {
            return;
        }

        this._timeIndicatorOffset = (this._date.getHours() * 60 + this._date.getMinutes()) * this.SLOT_RATIO;
    }

    private updateScrollbarOffsetLocation(delay: number): void {
        if (!this._timelineScrollerEleRef) {
            return;
        }

        this._scroller$.next(delay);
    }
}