import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ColorPickerMode } from "./ia-color.data";
import { environment } from "src/environments/environment";

@Component({
    selector: 'ca-color-picker',
    templateUrl: './ia-color-picker.component.html',
    styleUrls: ['./ia-color-picker.component.css']
})
export class IAColorPickerComponent {
    _enumColorMode: typeof ColorPickerMode = ColorPickerMode;

    @Input() id: string;
    @Input() title: string;
    @Input() colorMode: ColorPickerMode = environment.system.colorPicker;
    @Input() disabled: boolean = false;
    @Input() supportOpacity: boolean = false;

    _opacity: number = 1; // 0 - 1
    _combinedColor: string = '#000000ff';
    _colorText: string = '000000';
    _isColorTextFormatError: boolean = false;
    _color: string = '#000000';
    @Input()
    set color(c: string) {
        if (c) {
            this._color = c.slice(0, 7);
            this._opacity = IAColorPickerComponent.extractAlphaValue(c);
            this._combinedColor = IAColorPickerComponent.hexToRgba(this._color, this._opacity);

            if (this.colorMode === ColorPickerMode.Manual) {
                this._colorText = this._color.substring(1);
            }
            else {
                this._colorText = this._color;
            }
        }
    }

    @Output() onColorChanged: EventEmitter<string> = new EventEmitter();

    @ViewChild('colorPicker') colorPickerRef: ElementRef;

    launchColorPicker(): void {
        this.colorPickerRef.nativeElement.click();
    }

    changeColor(colorIn: string): void {
        let color: string = colorIn.substring(0, 1) === '#' ? colorIn : '#' + colorIn;
        this.onColorChanged.emit(IAColorPickerComponent.combineColorAndOpacity(color, this._opacity));
    }

    changeColorText(colorIn: string): void {
        this._isColorTextFormatError = colorIn.length != 6;
        if (colorIn.length == 6) {
            this.changeColor(colorIn);
        }
    }

    onOpacityUpdated(opacity: number): void {
        this.onColorChanged.emit(IAColorPickerComponent.combineColorAndOpacity(this._color, opacity));
    }

    private static combineColorAndOpacity(color: string, opacity: number): string {
        return color + this.opacityToHex(opacity);
    }

    private static opacityToHex(opIn: number): string {
        opIn = opIn * 2.55;
        opIn = Math.round(opIn);
        return opIn.toString(16);
    }

    private static hexToRgba(hex: string, alpha?: number): string {
        const r: number = parseInt(hex.slice(1, 3), 16);
        const g: number = parseInt(hex.slice(3, 5), 16);
        const b: number = parseInt(hex.slice(5, 7), 16);
        let a: number = 1;
        if (alpha !== undefined) {
            a = alpha > 100 ? 100 : alpha;
            a = alpha / 100;
        }
        else {
            a = IAColorPickerComponent.extractAlphaValue(hex);
        }

        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    private static extractAlphaValue(color: string, defaultHexAlphaValue = 'ff'): number {
        return Math.round(parseInt((color.slice(7, 9) || defaultHexAlphaValue), 16) * 100 / 255);
    }
}