import { EventEmitter, Injectable, Output } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CalendarAction } from "src/app/calendar/lib/calendar.data";
import { CalendarService } from "src/app/calendar/lib/calendar.service";
import { CALogger } from "src/app/lib/logger";

@Injectable({
    providedIn: 'root'
})
export class BadgeService {
    private readonly BADGE_ALLOW_LIST_FILE: string = 'badge.txt';
    private readonly BADGE_ALLOW_LIST_MAX_LEN: number = 200;
    private readonly BADGE_ID_MAX_LEN: number = 100;
    private _badgeIDList: string[] = [];
    get availableBadgeIDList(): string[] {
        return this._badgeIDList;
    }
    private _enableBadgeDetection: boolean = false;
    private _badgeID: string;


    @Output() onCalendarAction: EventEmitter<{ action: CalendarAction }> = new EventEmitter();

    constructor(private calendarSvc: CalendarService) {}

    init(enableBadgeDetection: boolean): Observable<{ isFault: boolean, errorType?: string, errorMessage?: string }> {
        CALogger.logInfo('badgeSvc', `init badge detection: ${enableBadgeDetection}`);
        this._enableBadgeDetection = enableBadgeDetection;
        // load allowlist
        return this._enableBadgeDetection ? from(this.calendarSvc.getTextFile(this.BADGE_ALLOW_LIST_FILE)).pipe(
            map((res: { isFault: boolean, data?: string, errorCode?: string, errorMessage?: string }) => {
                if (res.isFault) {
                    if (res.errorCode === 'itemNotFound') {
                        throw { errorType: 'File Not Found', errorMessage: 'Whitelist ID file is missing. Ensure the correct file is provided and try again.' };
                    }
                }

                this._badgeIDList = res.data?.split('\r\n');
                // check if white list is too long
                if (this._badgeIDList.length > this.BADGE_ALLOW_LIST_MAX_LEN) {
                    throw { errorType: 'Line Limit Exceeded', errorMessage: `The whitelist allows a maximum of ${this.BADGE_ALLOW_LIST_MAX_LEN} IDs. Please reduce the number of IDs in the file and retry` };
                }

                // check if id length is too long
                for (let id of this._badgeIDList) {
                    if (id.length > this.BADGE_ID_MAX_LEN) {
                        throw { errorType: 'ID Length Exceeded', errorMessage: `Each ID should be ${this.BADGE_ID_MAX_LEN} characters or fewer. Please ensure all IDs comply with this limit.` };
                    }
                }

                return { isFault: false };
            }),
            catchError((err) => {
                this._badgeIDList = [];
                return of(Object.assign({ isFault: true }, err))
            })
        ) : of({ isFault: false });
    }

    identifyBadgeID(calendarAction: CalendarAction): boolean {
        if (this._enableBadgeDetection && !this._badgeID) {
            CALogger.logInfo('badgeSvc', 'need badge verification');
            this.onCalendarAction.emit({ action: calendarAction });
            return false;
        }

        return true;
    }

    setBadgeID(id: string): void {
        this._badgeID = id;
    }
}