import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { Location } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountInfo } from '@azure/msal-common';
import { endOfDay, startOfDay } from 'date-fns/esm';

import { AuthService } from '../../lib/auth.service';
import { IGraphMultiItem } from './msGraph.data';
import { IAEventInfo, ICalendarWorkplace, SpaceInfo } from './calendar.data';
import { IAConfig } from '../../app-config.model';
import { environment } from '../../../environments/environment';
import { CALogger } from '../../../../src/app/lib/logger';
import { AppConfigService } from '../../../../src/app/app-config.service';

export class CalendarWorkplaceAzure implements ICalendarWorkplace {
    // event showAs state on Outlook: free, workingElsewhere, busy, tentative, oof
    private readonly EVENT_SHOWAS_STATE_BUSY: string = 'busy';
    private readonly EVENT_SHOWAS_STATE_TENTATIVE: string = 'tentative';
    readonly CONFIG_FOLDER: string = environment.system.configRootFolder;
    readonly TAG: string = 'azure';
    CONFIG_SHOW_UNBUSY_EVENT: boolean = AppConfigService.config.calendar.showUnBusyEvent;
    token: string;
    private _driveID: string;

    get account(): AccountInfo {
        return this.authSvc.activeAccount;
    }

    private _resourceAccount: { username: string, name: string };
    get resourceAccount(): { username: string, name: string } {
        return this._resourceAccount || { username: this.account?.username, name: this.account?.name };
    }
    set resourceAccount(account: { username: string, name: string }) {
        this._resourceAccount = account;
    }

    onActiveAccountChanged?: EventEmitter<{ name: string; username: string; }> = new EventEmitter<{ name: string; username: string; }>();

    constructor(
        private http: HttpClient,
        private authSvc: AuthService,
        private location: Location) {
        this.authSvc.loginStatusChanged.subscribe((status: { isLogin: boolean, account?: AccountInfo }) => {
            if (status.isLogin) {
                this.onActiveAccountChanged.emit({ username: status.account?.username, name: status.account?.name });
            }
        });
    }

    async getAccount(deviceIdentityId: string): Promise<{ isFault: boolean; errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    async getRooms(): Promise<{ isFault: boolean, data?: MicrosoftGraph.Room[], error?: string | number, errorMessage?: string; }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            const ret: IGraphMultiItem<MicrosoftGraph.Room> = await this.authSvc.graphClient
                .api('/places/microsoft.graph.room')
                .top(50)
                .get();

            return { isFault: false, data: ret.value };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async getCalendarByDate(d: Date): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        const date: Date = d;
        try {
            const ret: IGraphMultiItem<MicrosoftGraph.Event> = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/calendar/calendarView')
                .query({
                    startDateTime: startOfDay(date).toISOString(),
                    endDateTime: endOfDay(date).toISOString()
                })
                //.select('subject,organizer,isOrganizer,start,end,allowNewTimeProposals,webLink,isAllDay,sensitivity')
                .orderby('start/dateTime')
                .top(50)
                .get();


            const rawEvents = this.CONFIG_SHOW_UNBUSY_EVENT ? ret.value : ret.value.filter(ev => ev.showAs === this.EVENT_SHOWAS_STATE_BUSY || ev.showAs === this.EVENT_SHOWAS_STATE_TENTATIVE);
            return { isFault: false, data: { calendar: rawEvents.map((ev: MicrosoftGraph.Event) => new IAEventInfo(ev)), space: new SpaceInfo() } };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async getMail(event: IAEventInfo): Promise<{ isFault: boolean, data?: any, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        try {
            const ret: IGraphMultiItem<MicrosoftGraph.Event> = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/messages') // + "?$expand=microsoft.graph.eventMessage/event($filter=id eq \'" + event.id + "\')")
                .expand(`microsoft.graph.eventMessage/event($select=id,subject;$filter=id eq '${event.id}')`)
                //.filter(`id eq '${event.id}'`)
                .select('microsoft.graph.eventMessage/event')
                .top(10)
                .get();

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        const endDateTime = new Date(event.endDate);
        endDateTime.setMinutes(endDateTime.getMinutes() + durationInMinute);
        CALogger.logInfo(this.TAG, `extend event with end time: ${endDateTime}`);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id)
                .patch({ end: { dateTime: endDateTime.toISOString().replace('Z', ''), timeZone: 'UTC' } });

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean = false): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        CALogger.logInfo(this.TAG, `create adhoc event ${subject} between ${startDate} - ${endDate}`);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/calendar/events')
                .post({
                    subject: subject,
                    start: { dateTime: isAllDay ? startDate.toISOString().replace(/T.*Z$/, '') : startDate.toISOString().replace('Z', ''), timeZone: 'UTC' },
                    end: { dateTime: isAllDay ? endDate.toISOString().replace(/T.*Z$/, '') : endDate.toISOString().replace('Z', ''), timeZone: 'UTC' },
                    isAllDay: isAllDay
                });

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async stopEvent(event: IAEventInfo, stopDateTime: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        const endDateTime: Date = stopDateTime;
        endDateTime.setSeconds(0, 0);
        //if (endDateTime.getSeconds() > 0) {
        //    endDateTime.setMinutes(endDateTime.getMinutes() - 1);
        //}
        CALogger.logInfo(this.TAG, `stop event at ${endDateTime}`);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id)
                .patch({ end: { dateTime: endDateTime.toISOString().replace('Z', ''), timeZone: 'UTC' } });

            return { isFault: false, data: endDateTime };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    async cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        CALogger.logInfo(this.TAG, 'cancel event: ', event);

        if (!event.isOrganizer) {
            return await this.declineEvent(event);
        }

        try {
            //how to get the status === 202 ?
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id + '/cancel')
                .header('observe', 'response')
                .header('reponseType', 'raw') //?
                .post({ comment: comment })

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }

        /*         
        return this.http.post<void>(this.GRAPH_USER_ENDPOINT + this._account.username + '/events/' + eventID + '/cancel', { comment: comment }, { observe: 'response' }).pipe(
                    map((res: HttpResponse<void>) => {
                        return res.status === 202 ? true : false;
                    })
                ) 
        */
    }

    async declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        CALogger.logInfo(this.TAG, 'decline event: ', event);

        try {
            const ret: MicrosoftGraph.Event = await this.authSvc.graphClient
                .api('/users/' + this.resourceAccount.username! + '/events/' + event.id + '/decline')
                .post({});

            return { isFault: false };
        }
        catch (error) {
            return { isFault: true, errorMessage: error.toString() };
        }
    }

    checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean; errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    async getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string }> {
        const ret: { isFault: boolean, data?: string, errorMessage?: string } = await this.getFile<string>(environment.system.configFilename, 'text');
        try {
            const data = ret.data ? JSON.parse(ret.data) : ret.data;
            return { isFault: ret.isFault, data: data, errorMessage: ret.errorMessage };
        }
        catch (err) {
            return { isFault: true, errorMessage: err.message };
        }
    }

    async getBlobFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob, mimeType?: string }, error?: string | number, errorMessage?: string }> {
        return this.getFile<{ content: Blob, mimeType?: string }>(relPath, 'blob');
    }

    async getTextFile(relPath: string): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string }> {
        return this.getFile<string>(relPath, 'text');
    }

    private async getFile<T>(fileRelPath: string, type: 'blob' | 'text'): Promise<{ isFault: boolean, data?: T, error?: string | number, errorMessage?: string }> {
        if (!this.authSvc.graphClient) {
            return { isFault: true, errorMessage: 'No graph client' };
        }

        // get first-layer document under root document library
        // /sites/{site-id}/drive/items/root/children
        // /drives/{drive-id}/items/root/children
        try {
            if (!this._driveID) {
                const drive: MicrosoftGraph.Drive = await this.authSvc.graphClient
                    .api(this.authSvc.isPersonalAccount ? '/users/' + this.resourceAccount.username! + '/drive' : '/sites/root')
                    .get();
                this._driveID = drive.id;
            }

            const file: MicrosoftGraph.DriveItem = await this.authSvc.graphClient
                .api((this.authSvc.isPersonalAccount ? '/drives/' + this._driveID : '/sites/' + this._driveID + '/drive') + '/root:/' + this.CONFIG_FOLDER + '/' + fileRelPath)
                .get();

            const resourceName: string = '@microsoft.graph.downloadUrl';
            if (!file[resourceName]) {
                return { isFault: true, errorMessage: `${fileRelPath} not found` };
            }

            let data: T;
            switch (type) {
                case 'blob':
                    {
                        const contentRaw: Blob = await this.http.get(file[resourceName], { responseType: 'blob' }).toPromise<Blob>();
                        data = { content: contentRaw, mimeType: file.file.mimeType } as any;
                    }
                    break;
                case 'text':
                    {
                        const configRaw: string = await this.http.get(file[resourceName], { responseType: 'text' }).toPromise<string>();
                        data = configRaw as any;
                    }
                    break;
            }

            return { isFault: false, data: data };
        }
        catch (error) {
            return { isFault: true, error: error.code, errorMessage: error.toString() };
        }
    }

    isUnpaired(errorCode: string | number): boolean {
        return false;
    }

    async getQRCodeLink(options?: { title: string, lang: string }): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string }> {
        return {
            isFault: false,
            data:
                window.location.href.substring(0, window.location.href.indexOf(this.location.path()))
                + '/?path=outlook-redirect&lang=' + options?.lang
                + '&title=' + encodeURI(options.title)
                + '&location=' + encodeURIComponent(this.account.name)
        };
    }
}