import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BadgeService } from "./badge.service";
import { fromEvent, merge, Subject, forkJoin, of, timer } from "rxjs";
import { bufferWhen, concatMap, debounceTime, filter, map, switchMap, takeUntil, takeWhile } from "rxjs/operators";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { CalendarAction } from "src/app/calendar/lib/calendar.data";
import { TranslateService } from "@ngx-translate/core";
import { CalendarHelper } from "../lib/calendar.helper";
import { IAdeaService } from "src/app/lib/iadea.service";
import { CALogger } from "src/app/lib/logger";
import { environment } from "src/environments/environment";
import { Helper } from "src/app/lib/helper";

@Component({
    selector: 'ca-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.css']
})
export class BadgeComponent implements OnInit, OnDestroy {
    private readonly TAG: string = 'badge';
    private readonly BADGE_DETECTION_DLG_COUNT_DOWN: number = 120; // seconds
    private readonly BADGE_CERTIFIED_DURATION: number = 300; // seconds
    private readonly BADGE_DETECTION_TYPE: 'WebNFC' | 'Keyboard' = 'Keyboard';
    private readonly _unsubscribe$: Subject<void> = new Subject();
    private readonly _badgeCertifiedCountdown$: Subject<void> = new Subject();
    private readonly _badgeDetectionCountdown$: Subject<number> = new Subject();
    private readonly _badgeDetectionClose$: Subject<void> = new Subject();
    readonly BADGE_BG_COLOR: string = Helper.hexToRgba('#ffffff', 0.9);
    readonly _badgeImgUrl: string = environment.resource.badge;

    readonly ICON_XMARK = faXmark;

    _badgeID: string;
    _isDetected: boolean = false;
    _badgeCertifiedCountdownStr: string;
    _badgeDetectionCountdownStr: string;
    _lastFrontLightbarInfo: { id: number, name: string, color: string, mode?: string };

    @ViewChild('btnDetectionBegin') _btnDetectionBeginRef: ElementRef;
    @ViewChild('btnDetectionEnd') _btnDetectionEndRef: ElementRef;

    constructor(private iadeaSvc: IAdeaService, private badgeSvc: BadgeService, private translateSvc: TranslateService) { }

    ngOnInit(): void {
        this.badgeSvc.onCalendarAction.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { action: CalendarAction }) => {
            if (!this._badgeID) {
                this.beginBadgeDetection();
            }
        });

        this._badgeDetectionCountdown$.pipe(
            switchMap((defaultCountdown: number) => {
                CALogger.logInfo(this.TAG, `begin badge detection countdown: ${defaultCountdown}`);
                let countdown: number = defaultCountdown;
                this._badgeDetectionCountdownStr = '';
                return timer(0, 1000).pipe(
                    concatMap(() => {
                        countdown--;
                        return forkJoin([this.translateSvc.get('lang.clause.closeCountdown', { '0': Math.floor(countdown / 60) + ':' + CalendarHelper.padTime(countdown % 60) }), of(countdown)]);
                    }),
                    map((res: [countdownStr: string, remainingSeconds: number]) => {
                        this._badgeDetectionCountdownStr = res[0];
                        return res[1];
                    }),
                    takeWhile(count => count >= 0)
                );
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((countdown: number) => {
            if (countdown == 0) {
                this.cancelBadgeDetection();
            }
        })

        this._badgeCertifiedCountdown$.pipe(
            switchMap(() => {
                CALogger.logInfo(this.TAG, 'begin cetified badge countdown');
                let countdown: number = this.BADGE_CERTIFIED_DURATION;
                this._badgeCertifiedCountdownStr = '';
                return timer(0, 1000).pipe(
                    concatMap(() => {
                        countdown--;
                        return forkJoin([this.translateSvc.get('lang.clause.badgeUsageTime', { '0': this._badgeID, '1': `${CalendarHelper.padTime(Math.floor(countdown / 60))}:${CalendarHelper.padTime(countdown % 60)}` }), of(countdown)]);
                    }),
                    map((res: [countdownStr: string, remainingSeconds: number]) => {
                        this._badgeCertifiedCountdownStr = res[0];
                        return res[1];
                    }),
                    takeWhile(count => count >= 0)
                );
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((countdown: number) => {
            if (countdown == 0) {
                this._badgeID = '';
                this.badgeSvc.setBadgeID(this._badgeID);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();

        this._badgeDetectionClose$.complete();
    }

    beginBadgeDetection(): void {
        CALogger.logInfo(this.TAG, `begin badge detection`);
        // register keyboard event
        if (this.BADGE_DETECTION_TYPE === 'Keyboard') {
            const keyup$ = fromEvent<KeyboardEvent>(document, 'keyup');
            // Capture keyup events and buffer them until there's inactivity (e.g., 300 ms)
            keyup$.pipe(
                filter(ev => ev.key !== 'Unidentified'),
                map(ev => ev.key),
                // Use debounceTime to wait for a pause (e.g., 300ms) in keyup events before processing the buffer
                bufferWhen(() => keyup$.pipe(debounceTime(300))),
                filter(keys => keys.length > 0),
                map(keys => keys.join('')),
                takeUntil(merge(this._unsubscribe$, this._badgeCertifiedCountdown$, this._badgeDetectionClose$))
            ).subscribe((data: string) => {
                CALogger.logInfo(this.TAG, `detected badge id: ${data}`);
                this._isDetected = true;
                const availableIDList: string[] = this.badgeSvc.availableBadgeIDList;
                if (availableIDList.length > 0 && availableIDList.indexOf(data) >= 0) {
                    this._badgeID = data;
                    this.badgeSvc.setBadgeID(this._badgeID);
                    this._badgeDetectionCountdown$.next(5);
                    this._badgeCertifiedCountdown$.next();
                }
            });
        }
        else if (this.BADGE_DETECTION_TYPE === 'WebNFC') {
            /*
            console.log('Badge', 'OnInit', 'Using WebNFC');
            if ('NDEFReader' in window) {
                const nfcReader = new NDEFReader();

                // Start scanning for NFC tags
                nfcReader.scan().then(() => {
                    console.log("NFC scan started successfully");

                    // Set up an event listener to read data from NFC tag
                    nfcReader.onreading = (event) => {
                        const { message, serialNumber } = event;
                        console.log(`NFC tag serial number: ${serialNumber}`);

                        // Loop through NFC message records
                        for (const record of message.records) {
                            // Process text records
                            if (record.recordType === "text") {
                                const textDecoder = new TextDecoder(record.encoding);
                                console.log(`NFC tag content: ${textDecoder.decode(record.data)}`);
                            } else {
                                console.log("Other record type: ", record.recordType);
                            }
                        }
                    };

                }).catch(error => {
                    console.log(`Error starting NFC scan: ${error}`);
                });

            } else {
                console.log('Badge', 'OnInit', 'WebNFC is not supported');
            }
            */
        }

        this._btnDetectionBeginRef.nativeElement.click();
        this._badgeDetectionCountdown$.next(this.BADGE_DETECTION_DLG_COUNT_DOWN);
        this.setLightbarToDetectMode();
    }

    cancelBadgeDetection(): void {
        CALogger.logInfo(this.TAG, 'stop badge detection');
        this._btnDetectionEndRef.nativeElement.click();
        this._badgeDetectionClose$.next();
        this._isDetected = false;
        this.setLightbarToOriginalMode();
    }

    setLightbarToDetectMode(host: string = 'localhost'): void {
        this.iadeaSvc.getLEDColor(host, '').pipe(
            concatMap((res: { id: number, name: string, color: string, mode?: string }[]) => {
                const frontLight = res?.find(l => l.name === 'front');
                if (frontLight) {
                    // save current front lightbar color & mode
                    this._lastFrontLightbarInfo = frontLight;
                    CALogger.logInfo(this.TAG, `set lightbar to detection mode`);
                    return this.iadeaSvc.setLEDColor(host, '', frontLight.id, frontLight.name, '#ff0000', 'slowblink', 1);
                }

                return of({});
            })
        ).subscribe((res: { error?: any, ledColor?: string, id?: number }) => {

        });
    }

    setLightbarToOriginalMode(host: string = 'localhost'): void {
        if (this._lastFrontLightbarInfo) {
            CALogger.logInfo(this.TAG, 'set lightbar to default mode: ', this._lastFrontLightbarInfo);
            this.iadeaSvc.setLEDColor(host, '', this._lastFrontLightbarInfo.id, this._lastFrontLightbarInfo.name, this._lastFrontLightbarInfo.color, this._lastFrontLightbarInfo.mode, 1).subscribe();
        }

        this._lastFrontLightbarInfo = null;
    }
}