import { Component, OnDestroy, OnInit } from "@angular/core";
import { concat, EMPTY, from, fromEvent, merge, Subject, timer } from "rxjs";
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";
import { CalendarScope, ErrorType } from "../calendar/lib/calendar.data";
import { AuthService } from "../lib/auth.service";
import { CacheService } from "../lib/cache.service";
import { AppConfigService } from "../app-config.service";
import { environment } from "src/environments/environment";
import { CALogger } from "../lib/logger";
import { CalendarService } from "../calendar/lib/calendar.service";

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    private readonly TAG: string = 'login';
    // configs
    CONFIG_FOREGROUND: string = AppConfigService.config.theme.foreground;
    _logoUrl: string = environment.resource.logo;
    _bgUrl: string = environment.resource.bg;
    _isOnline: boolean = true;
    _enumScope: typeof CalendarScope = CalendarScope;
    _scope: CalendarScope = environment.scope;
    _pairingCodes: string[];
    _errorMessage: string;

    _loading: boolean = false;
    // for auto-login check
    private readonly AUTO_LOGIN_CHECK_PERIOD: number = 300000;
    _loginCheck$: Subject<{ start: boolean, delay?: number }> = new Subject<{ start: boolean, delay: number }>();
    _isLoginTimerActive: boolean = false;

    private readonly _destroying$ = new Subject<void>();

    constructor(public authSvc: AuthService, private cacheSvc: CacheService, private calendarSvc: CalendarService) { }

    ngOnInit(): void {
        concat(timer(1000), merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(debounceTime(5000))).pipe(
            takeUntil(this._destroying$)
        ).subscribe(async () => {
            CALogger.logInfo(this.TAG, `online status change, online: ${window.navigator.onLine}`);
            this._isOnline = window.navigator.onLine;
        });

        from(this.cacheSvc.getLogo()).subscribe((res: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }) => {
            CALogger.logInfo(this.TAG, 'logo: ', res);
            if (!res.isFault && res.data) {
                this._logoUrl = res.data;
            }
        });

        from(this.cacheSvc.getBg()).subscribe((res: { isFault: boolean, data?: string, errorType?: ErrorType, errorMsg?: string, errorMsgParams?: any[] }) => {
            CALogger.logInfo(this.TAG, 'bg: ', res);
            if (!res.isFault && res.data) {
                this._bgUrl = res.data;
            }
        });

        // auto-login
        if (this._scope == CalendarScope.ServiceNow) {
            this._loginCheck$.pipe(
                switchMap((ret: { start: boolean, delay: number }) => {
                    if (ret.start) {
                        this._isLoginTimerActive = true;
                        return timer(ret.delay ?? 0);
                    }
                    else {
                        this._isLoginTimerActive = false;
                        return EMPTY;
                    }
                }),
                takeUntil(this._destroying$)
            ).subscribe({
                next: (index: number) => {
                    this.login();
                },
                complete: () => {
                    CALogger.logInfo(this.TAG, 'auto-check login status completed');
                }
            });

            this._loginCheck$.next({ start: true });
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    }

    login(): void {
        this._errorMessage = null;
        this._pairingCodes = [];
        this._loading = true;

        this.authSvc.signin(this.calendarSvc).subscribe((res: { isFault: boolean, pairingCode?: any, error?: any }) => {
            CALogger.logInfo(this.TAG, 'sign in res: ', res);
            
            this._loading = false;
            if (res.isFault) {
                if (res.pairingCode) {
                    this._pairingCodes = res.pairingCode.split('');
                }
                if (res.error) {
                    this._errorMessage = res.error?.errorMessage || res.error;
                }
            }

            if (this._scope == CalendarScope.ServiceNow) {
                // auto-check if device is paired periodically
                this._loginCheck$.next({ start: res.isFault ? true : false, delay: this._isLoginTimerActive ? this.AUTO_LOGIN_CHECK_PERIOD : 0 });
            }
        });
    }
}