<div class="mb-2 row">
    <label [for]="'#' + id" class="form-label col-5">{{ title | translate }}</label>
    <div class="col-7">
        <div *ngIf="supportOpacity; else templateNoOpacity">
            <div data-bs-toggle="collapse" [attr.data-bs-target]="'#' + id" class="form-control form-control-sm"
                [style.background]="_combinedColor" aria-expanded="false">
            </div>
            <div [id]="id" class="collapse">
                <div class="color-setting">
                    <ng-container [ngSwitch]="colorMode">
                        <ng-template [ngSwitchCase]="_enumColorMode.Default">
                            <input [id]="id" type="color" class="form-control" [disabled]="disabled" [ngModel]="_color"
                                (ngModelChange)="changeColor($event)">
                        </ng-template>
                        <ng-template [ngSwitchCase]="_enumColorMode.Manual">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text" [style.background-color]="_color"
                                    (click)="launchColorPicker()"></span>
                                <input #colorPicker [id]="'color-' + id" type="color" class="color-input-hide"
                                    [disabled]="disabled" [ngModel]="_color" (ngModelChange)="changeColor($event)">
                                <span class="input-group-text">#</span>
                                <input [id]="'color-text-' + id" type="text" class="form-control" [disabled]="disabled" [class.warn]="_isColorTextFormatError"
                                    [ngModel]="_colorText" maxlength="6" (ngModelChange)="changeColorText($event)">
                            </div>
                        </ng-template>
                    </ng-container>
                    <div class="mt-2">
                        <div class="d-flex align-items-center">
                            <small>{{ 'lang.word.opacity' | translate }}</small>
                            <span class="ms-2 badge bg-secondary">{{ _opacity }} %</span>
                        </div>
                        <input type="range" min="0" max="100" step="1" class="w-100" [disabled]="disabled" [ngModel]="_opacity"
                            (ngModelChange)="onOpacityUpdated($event)">
                    </div>
                </div>
            </div>
        </div>
        <ng-template #templateNoOpacity>
            <ng-container [ngSwitch]="colorMode">
                <ng-template [ngSwitchCase]="_enumColorMode.Default">
                    <input [id]="id" type="color" class="form-control" [disabled]="disabled" [ngModel]="_color"
                        (ngModelChange)="changeColor($event)">
                </ng-template>
                <ng-template [ngSwitchCase]="_enumColorMode.Manual">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text" [style.background-color]="_color"
                            (click)="launchColorPicker()"></span>
                        <input #colorPicker [id]="'color-' + id" type="color" class="color-input-hide"
                            [disabled]="disabled" [ngModel]="_color" (ngModelChange)="changeColor($event)">
                        <span class="input-group-text">#</span>
                        <input [id]="'color-text-' + id" type="text" class="form-control" [disabled]="disabled"
                            [ngModel]="_colorText" maxlength="6" (ngModelChange)="changeColor($event)">
                    </div>
                </ng-template>
            </ng-container>
        </ng-template>
    </div>
</div>