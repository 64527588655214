import { Injectable, EventEmitter, Output } from "@angular/core";
import { Location } from '@angular/common';
import { CalendarScope, IAEventInfo, ICalendarWorkplace, SpaceInfo } from "./calendar.data";
import { IAConfig } from "src/app/app-config.model";
import { CalendarWorkplaceMockup } from "./calendar.workplace.Mockup";
import { CalendarWorkplaceAzure } from "./calendar.workplace.Azure";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/lib/auth.service";
import { AccountInfo } from "@azure/msal-browser";
import { CalendarWorkplaceServiceNow } from "./calendar.workplace.ServiceNow";
import { CALogger } from "src/app/lib/logger";

@Injectable({
    providedIn: 'root'
})
export class CalendarService implements ICalendarWorkplace {
    private _workplace: ICalendarWorkplace;
    readonly TAG: string = 'calSvc';
    get token(): string {
        return this._workplace?.token;
    }

    private _scope: CalendarScope = CalendarScope.Unknown;
    get scope(): CalendarScope {
        return this._scope;
    }

    get account(): AccountInfo {
        return this._workplace?.account;
    }

    get resourceAccount(): { username: string, name: string, id?: string } {
        return this._workplace?.resourceAccount;
    }
    set resourceAccount(account: { username: string, name: string, id?: string }) {
        if (!this._workplace) {
            throw 'No valid workplace';
        }
        this._workplace.resourceAccount = account;
    }

    constructor(private http: HttpClient, private location: Location, private authSvc: AuthService) { }

    @Output() onActiveAccountChanged = new EventEmitter<{ name: string; username: string; }>();

    setScope(scope: CalendarScope): void {
        if (this._scope !== scope) {
            CALogger.logInfo('calSvc', `set scope to ${scope}`);
            this._scope = scope;
            switch (this._scope) {
                case CalendarScope.Mockup:
                    {
                        this._workplace = new CalendarWorkplaceMockup();
                    }
                    break;
                case CalendarScope.Azure:
                    {
                        this._workplace = new CalendarWorkplaceAzure(this.http, this.authSvc, this.location);
                    }
                    break;
                case CalendarScope.ServiceNow:
                    {
                        this._workplace = new CalendarWorkplaceServiceNow(this.http);
                    }
                    break;
            }

            this._workplace?.onActiveAccountChanged?.subscribe((res: { name: string; username: string; }) => {
                this.onActiveAccountChanged.emit(res);
            });
        }
    }

    setToken(token?: string): void {
        this._workplace.token = token || '';
    }

    async getAccount(deviceIdentityId: string): Promise<{ isFault: boolean, data?: any, error?: string | number, errorMessage?: string }> {
        return await this.preCheckWorkplace(() => this._workplace.getAccount(deviceIdentityId));
    }
    async getRooms(): Promise<{ isFault: boolean, data?: microsoftgraph.Room[], error?: string | number, errorMessage?: string }> {
        return await this.preCheckWorkplace(() => this._workplace.getRooms());
    }
    async getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.getConfig());
    }
    async getCalendarByDate(d: Date, force: boolean = false): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.getCalendarByDate(d, force));
    }
    async extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.extendEvent(event, durationInMinute));
    }
    async addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.addEvent(subject, startDate, endDate, isAllDay));
    }
    async stopEvent(event: IAEventInfo, stopDateTime: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.stopEvent(event, stopDateTime));
    }
    async cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.cancelEvent(event, comment));
    }
    async declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.declineEvent(event));
    }
    async checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.checkInEvent(event));
    }
    async checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.checkOutEvent(event));
    }
    async getBlobFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob; mimeType?: string; }, error?: string | number, errorMessage?: string; }> {
        return await this.preCheckWorkplace(() => this._workplace.getBlobFile(relPath));
    }
    async getTextFile(relPath: string): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string }> {
        return this.preCheckWorkplace(() => this._workplace.getTextFile(relPath));
    }
    isUnpaired(errorCode: string | number): boolean {
        return this._workplace?.isUnpaired(errorCode);
    }
    async getQRCodeLink(options?: { title: string, lang: string }): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string }> {
        return await this._workplace?.getQRCodeLink(options);
    }

    private async preCheckWorkplace(promiseThenFunc: any): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        if (!this._workplace) {
            return { isFault: true, errorMessage: 'workplace is not ready' };
        }

        return await promiseThenFunc();
    }
}