import { IAConfig } from "src/app/app-config.model";
import { IAEventInfo, ICalendarWorkplace, SpaceInfo } from "./calendar.data";
import { AccountInfo } from "@azure/msal-browser";
import { CALogger } from "src/app/lib/logger";

export class CalendarWorkplaceMockup implements ICalendarWorkplace {
    readonly TAG: string = 'mockup';
    private _account: AccountInfo;
    get account(): AccountInfo {
        return this._account;
    }
    private _resourceAccount: { username: string, name: string };
    get resourceAccount(): { username: string, name: string } {
        return this._resourceAccount || { username: this.account.username, name: this.account.name };
    }
    set resourceAccount(account: { username: string, name: string }) {
        this._resourceAccount = account;
    }
    static MOCKUP_EVENT_ID: number = 1;
    
    token: string;
    private _events: IAEventInfo[] = [];

    constructor() {
        this._account = {
            username: 'Demo@IAdea.com',
            name: 'Workplace 16',
            homeAccountId: '',
            environment: '',
            tenantId: '',
            localAccountId: 'Workplace',
        };
    }

    getAccount(deviceIdentityId: string): Promise<{ isFault: boolean; errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    getRooms(): Promise<{ isFault: boolean; data?: any[]; errorMessage?: string; }> {
        throw new Error("Method not implemented.");
    }

    getCalendarByDate(d: Date): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string; }> {
        return Promise.resolve({ isFault: false, data: { calendar: this._events.map(e => e.copy()), space: new SpaceInfo() } });
    }

    addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean): Promise<{ isFault: boolean; errorMessage?: string; }> {
        CALogger.logInfo(this.TAG, `create adhoc event ${subject} between ${startDate} - ${endDate}`);

        let newEvent: IAEventInfo = new IAEventInfo();
        newEvent.id = `mockup-event-${CalendarWorkplaceMockup.MOCKUP_EVENT_ID++}`;
        newEvent.etag = this.getMockupEtag();
        newEvent.attendees = [];
        newEvent.body = null;
        newEvent.startDate = startDate;
        newEvent.endDate = endDate;
        newEvent.isAllDay = isAllDay;
        newEvent.isOrganizer = false;
        newEvent.organizer = null;
        newEvent.subject = subject;
        newEvent.webLink = null;
        newEvent.allowNewTimeProposals = false;
        newEvent.isPrivate = false;
        newEvent.isCheckin = false;

        this._events.push(newEvent);

        return Promise.resolve({ isFault: false });
    }

    extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        const found: IAEventInfo = this._events.find(e => e.id === event.id);
        if (!found) {
            return Promise.resolve({ isFault: true, errorMessage: `Cannot find the event ${event.id}` });
        }

        const endDateTime = new Date(event.endDate);
        endDateTime.setMinutes(endDateTime.getMinutes() + durationInMinute);
        // need update etag since event is changed
        found.endDate = endDateTime;
        found.etag = this.getMockupEtag();

        CALogger.logInfo(this.TAG, `extend event with end time: ${endDateTime}`);

        return Promise.resolve({ isFault: false });
    }

    stopEvent(event: IAEventInfo, stopDateTime?: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string; }> {
        const found: IAEventInfo = this._events.find(e => e.id === event.id);
        if (!found) {
            return Promise.resolve({ isFault: true, errorMessage: `Cannot find the event ${event.id}` });
        }

        const endDateTime: Date = stopDateTime || new Date();
        endDateTime.setSeconds(0, 0);

        found.endDate = endDateTime;
        CALogger.logInfo(this.TAG, `stop event at ${endDateTime}`);
        // need update etag since event is changed
        found.etag = this.getMockupEtag();

        return Promise.resolve({ isFault: false, data: endDateTime });
    }

    cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        CALogger.logInfo(this.TAG, 'cancel event: ', event);

        const found: IAEventInfo = this._events.find(e => e.id === event.id);
        if (!found) {
            return Promise.resolve({ isFault: true, errorMessage: `Cannot find the event ${event.id}` });
        }

        this._events.splice(this._events.indexOf(found), 1);

        return Promise.resolve({ isFault: false });
    }

    declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        CALogger.logInfo(this.TAG, 'decline event: ', event);

        return this.cancelEvent(event);
    }

    checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        const found: IAEventInfo = this._events.find(e => e.id === event.id);
        if (!found) {
            return Promise.resolve({ isFault: true, errorMessage: `Cannot find the event ${event.id}` });
        }

        found.etag = this.getMockupEtag();
        found.isCheckin = true;

        return Promise.resolve({ isFault: false });
    }

    checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string; }> {
        return this.cancelEvent(event);
    }

    getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string; }> {
        return Promise.resolve({ isFault: true, errorMessage: 'Not support' });
    }

    getBlobFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob; mimeType?: string; }, error?: string | number, errorMessage?: string; }> {
        return Promise.resolve({ isFault: true, errorMessage: 'Not support' });
    }

    async getTextFile(relPath: string): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string}> {
        return Promise.resolve({ isFault: true, errorMessage: 'Not support' });
    }

    isUnpaired(errorCode: string | number): boolean {
        return false;
    }

    async getQRCodeLink(options?: { title: string, lang: string }): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string }> {
        return await Promise.resolve({ isFault: true, errorMessage: 'Unsupport' });
    }

    private getMockupEtag(): string {
        return `mockup-event-etag-${Date.now()}`;
    }
}