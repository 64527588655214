import { EventEmitter, Injectable, Output } from "@angular/core";
import { environment } from '../../environments/environment';
import { LicenseInfo } from "./iadea/license/license.data";
import { IAdeaService } from "./iadea.service";
import { catchError, concatMap, map, scan, switchMap } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject, concat, interval, of, zip } from "rxjs";
import { Helper } from "./helper";
import { CalendarScope } from "../calendar/lib/calendar.data";
import { CALogger } from "./logger";


@Injectable()
export class LicenseService {
    private readonly LicenseUpdateDuration: number = 3600000; // 1 hour
    private readonly TokenUpdateDuration: number = 3600000; // 1 hour
    private readonly LicenseCheckDuration: number = 3600000; // 1 hour
    private readonly LicenseCheckDurationWithoutLicense: number = 1200000; // 20 min
    private readonly LicenseCategory: string = 'com.iadea.workplace.room';
    private readonly LicenseScopes: string[] = ['Workplace.Room.Booking', 'Workplace.Room.Booking.Outlook'];
    private readonly HeaderKeyPair: { key: string, value: any } = { key: 'IAdeaCare-Player-ID', value: 'app:BookingForWorkplace' };
    private readonly TAG: string = 'licenseSvc';
    private _licenseServer: string = environment.license.server;
    private _license: LicenseInfo;
    private _lastLicenseUpdateTime: Date;
    private _idToken: string; // id_token;
    private _lastTokenUpdateTime: Date;
    private _scope: CalendarScope = environment.scope;
    private _licenseCheck$: Subject<void> = new Subject<void>();
    private _licenseCheckDuration$: BehaviorSubject<number> = new BehaviorSubject<number>(this.LicenseCheckDuration);

    @Output() onTokenUpdated: EventEmitter<{ isFault: boolean, token?: string, errorMessage?: string }> = new EventEmitter();
    @Output() onLicenseUpdated: EventEmitter<{ license: LicenseInfo, error?: string, triggerAlert?: boolean }> = new EventEmitter();

    constructor(private iadeaSvc: IAdeaService) {
        if (Helper.isTopWindow) {
            window.addEventListener('message', this.handleMessage.bind(this), false);
        }

        this._licenseCheck$.pipe(
            switchMap(() =>
                // Emit immediately, then start interval based on the current duration
                concat(of(0), this._licenseCheckDuration$.pipe(
                    switchMap((duration) => interval(duration))
                )).pipe(
                    scan((acc) => acc, 0)
                )
            ),
            concatMap((index: number) => zip(of(index), this.getLicense('periodic check')))
        ).subscribe((res: [number, { license: LicenseInfo, error?: string, triggerAlert?: boolean }]) => {
            CALogger.logInfo(this.TAG, `get license at ${res[0]} time , res: `, res[1]);
            this.onLicenseUpdated.emit(Object.assign(res[1], { triggerAlert: res[0] > 0 || res[1].triggerAlert }));
        });
    }

    getIDTokenInfo(): { url: string, method: string, fragments: { name: string, value: string | number }[] } {
        return {
            url: `https://${this._licenseServer}/iAdeaCare/v1/oauth2/v2.0/devices/authorize`,
            method: 'Get',
            fragments: [
                { name: 'client_id', value: this._licenseServer },
                { name: 'nonce', value: 1234 },
                { name: 'response_mode', value: 'fragment' },
                { name: 'redirect_uri', value: `${window.location.href}?path=token-parser` }
            ]
        };
    }

    checkLicense(scope: CalendarScope): void {
        CALogger.logInfo(this.TAG, 'check license');
        this._scope = scope;
        this._licenseCheck$.next();
    }

    private getLicense(reason: string): Observable<{ license: LicenseInfo, error?: string, triggerAlert?: boolean }> {
        CALogger.logInfo(this.TAG, `get license by ${reason}`);
        if (environment.system.lockByIAdea && !this.iadeaSvc.isIAdeaDevice(navigator.userAgent)) {
            CALogger.logInfo(this.TAG, 'not an IAdea device');
            return of({ license: null, error: `Not an IAdea device. User agent: ${navigator.userAgent}`, triggerAlert: true });
        }

        if (this._scope == CalendarScope.Mockup || environment.license.trial) {
            CALogger.logInfo(this.TAG, 'use mockup license');
            const now: Date = new Date();
            now.setFullYear(now.getFullYear() + 1);

            return of({
                license: {
                    currentActivated: ['booking-basic-bundle'],
                    isExpired: false,
                    expiryDate: now.toISOString(),
                    scope: this.LicenseScopes
                }
            });
        }

        if (this._license && (Date.now() - this._lastLicenseUpdateTime?.getTime()) < this.LicenseUpdateDuration) {
            return of({ license: this._license });
        }

        if (environment.license.mode === 'idToken') {
            if (!this._idToken) {
                this.onTokenUpdated.emit({ isFault: true, errorMessage: 'No token' });
                return of({ license: null, error: 'No token' });
            }

            // If id_token is expired, use the current license temporary.
            if ((Date.now() - this._lastTokenUpdateTime?.getTime()) >= this.TokenUpdateDuration) {
                this.onTokenUpdated.emit({ isFault: true, errorMessage: 'Token is expired' });
                return of({ license: this._license });
            }

            this.onTokenUpdated.emit({ isFault: false, token: this._idToken });
        }

        return of(true).pipe(
            concatMap(() => {
                switch (environment.license.mode) {
                    case 'idToken':
                        return this.iadeaSvc.getLicenseByIDToken(this._licenseServer, this._idToken, { headerKeyPairs: [this.HeaderKeyPair] });
                    case 'signature':
                        return this.iadeaSvc.getDeviceSignature().pipe(
                            concatMap((ret: { isFault: boolean, data: any, errorMessage?: string }) => {
                                if (ret.isFault) {
                                    throw ret.errorMessage;
                                }

                                return this.iadeaSvc.getLicenseByDeviceSignature(environment.license.server, ret.data, this.LicenseCategory, { headerKeyPairs: [{ key: 'IAdeaCare-Player-ID', value: 'app:BookingForWorkplace' }] });
                            })
                        );
                    default:
                        throw 'Unsupport license mode';
                }
            }),
            map((res: { licenses?: { [category: string]: LicenseInfo; }, scope?: string[], error?: string }) => {
                if (res.error) {
                    throw res.error;
                }

                if (!res.licenses?.[this.LicenseCategory]) {
                    throw 'No license';
                }

                this._license = res.licenses[this.LicenseCategory];

                const currentLicenseScopeSet = new Set(res.scope);
                if (!this.LicenseScopes.some(scopeToDetect => currentLicenseScopeSet.has(scopeToDetect))) {
                    throw 'Invalid license scopes';
                }

                if (this._license.isExpired) {
                    throw `License is expired on ${this._license.expiryDate.toString()}`;
                }

                this._lastLicenseUpdateTime = new Date();
                if (this._licenseCheckDuration$.value !== this.LicenseCheckDuration) {
                    CALogger.logInfo(this.TAG, `set license check duration to ${this.LicenseCheckDurationWithoutLicense} since license access is pass`);
                    this._licenseCheckDuration$.next(this.LicenseCheckDuration);
                }

                return { license: this._license };
            }),
            catchError((error) => {
                if (this._licenseCheckDuration$.value !== this.LicenseCheckDurationWithoutLicense) {
                    CALogger.logError(this.TAG, `set license check duration to ${this.LicenseCheckDurationWithoutLicense} since license access is fail`);
                    this._licenseCheckDuration$.next(this.LicenseCheckDurationWithoutLicense);
                }
                
                return of({ license: null, error: error, triggerAlert: true })
            })
        );
    }

    private handleMessage(ev: MessageEvent<any>): void {
        if (ev.origin !== window.location.origin || !ev.data || typeof ev.data !== 'string') {
            return;
        }
        
        CALogger.logInfo(this.TAG, `get msg: `, ev);
        if (this._idToken != ev.data) {
            this._idToken = ev.data;
            this._lastTokenUpdateTime = new Date();

            this.getLicense('idToken from postMessage').subscribe((res: { license: LicenseInfo, error?: string }) => {
                CALogger.logInfo(this.TAG, `get license by post msg, res: `, res);
                this.onLicenseUpdated.emit(Object.assign(res, { triggerAlert: true }));
            });
        }
    }
}