import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { IAConfig, IALocalConfig } from '../../app-config.model';
import { CacheService } from '../../lib/cache.service';
import { ISlideFuncComponent, SlideAction } from './slide-func.data';

@Component({
    templateUrl: './local-config-slide.component.html',
    styleUrls: ['./slide.style.css', './local-config-slide.component.css',]
})
export class LocalConfigSlideComponent implements ISlideFuncComponent<{ config: IAConfig }, { config?: IAConfig, useLocalConfig?: boolean, to?: SlideAction }>, OnInit {
    onApprove: (action: SlideAction, data?: { config?: IAConfig, useLocalConfig?: boolean, to?: SlideAction }) => void;
    onReject: (action: SlideAction, data?: { config?: IAConfig, useLocalConfig?: boolean, to?: SlideAction }) => void;

    title: string;
    action: SlideAction;
    data?: { config: IAConfig };

    readonly ICON_BACK = faChevronLeft;
    readonly ICON_SLIDER = faSlidersH;
    readonly LANGUAGE_LIST: { key: string, name: string }[] = [
        { key: 'en-US', name: 'English (United States)' },
        { key: 'zh-TW', name: '中文' },
        { key: 'fr-FR', name: 'français (France)' },
        { key: 'es-ES', name: 'español (España, alfabetización internacional)' }
    ];
    readonly TIME_FORMAT_LIST: { key: string, name: string, value: boolean }[] = [
        { key: 'hour12', name: 'lang.word.12hourClock', value: true },
        { key: 'hour24', name: 'lang.word.24hourClock', value: false }
    ];

    _useLocalConfig: { before: boolean, after: boolean };
    _config: { before: IALocalConfig, after: IALocalConfig };

    constructor(private cacheSvc: CacheService) {
        this._useLocalConfig = { before: this.cacheSvc.useLocalConfig, after: this.cacheSvc.useLocalConfig };
        this._config = { before: new IALocalConfig().merge(this.cacheSvc.localConfig), after: new IALocalConfig().merge(this.cacheSvc.localConfig) };
    }

    selectTimeFormat(option: string): void {
        this._config.after.dateTimeOption.hour12 = JSON.parse(option);
    }

    ngOnInit(): void {
        if (this.data?.config) {
            if (this._config.before.isUnset()) {
                this._config.before.merge(this.data.config);
            }
            if (this._config.after.isUnset()) {
                this._config.after.merge(this.data.config);
            }
        }
    }

    goAdvanceHome(): void {
        this.onApprove(SlideAction.LocalConfig, { to: SlideAction.Advance });
    }

    reset(): void {
        this._useLocalConfig.after = this._useLocalConfig.before;
        this._config.after.merge(this._config.before);
    }

    confirm(): void {
        this.onApprove(SlideAction.LocalConfig, { config: this._config.after, useLocalConfig: this._useLocalConfig.after });
    }
}