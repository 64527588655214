import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { catchError, concatMap, filter, map } from 'rxjs/operators';
import { OAuthSettings, PERSONAL_TENANTID } from '../auth';
import { environment } from 'src/environments/environment';
import { Helper } from './helper';
import { CalendarScope } from '../calendar/lib/calendar.data';
import { IAdeaService } from './iadea.service';
import { HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of, timer } from 'rxjs';
import { CALogger } from './logger';
import { CalendarService } from '../calendar/lib/calendar.service';
import { Client } from '@microsoft/microsoft-graph-client';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly TAG: string = 'authSvc';
    private _bValidateUser: boolean = false;
    private _scope: CalendarScope = environment.scope;
    public activeAccount: AccountInfo;
    public graphClient: Client;
    private _deviceIdentity: { uuid: string, pairingCode: string };
    get deviceIdentity(): { uuid: string, pairingCode: string } {
        return this._deviceIdentity;
    }

    get isPersonalAccount(): boolean {
        return this.activeAccount?.tenantId === PERSONAL_TENANTID;
    }

    @Output() loginStatusChanged = new EventEmitter<{ isLogin: boolean, account?: AccountInfo, scope: CalendarScope }>();

    constructor(
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadcastService: MsalBroadcastService,
        private iadeaSvc: IAdeaService) {

        if (Helper.isTopWindow) {
            switch (this._scope) {
                case CalendarScope.Azure:
                    {
                        this.msalBroadcastService.msalSubject$.pipe(
                            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                        ).subscribe((result: EventMessage) => {
                            CALogger.logInfo(this.TAG, `[${this._scope}] login pass: `, result);
                            if (!this._bValidateUser) {
                                const payload = result.payload as AuthenticationResult;
                                CALogger.logInfo(this.TAG, `[${this._scope}] set active account to `, payload.account);
                                this.msalService.instance.setActiveAccount(payload.account);
                            }
                        });

                        this.msalBroadcastService.inProgress$.pipe(
                            filter((status: InteractionStatus) => !this._bValidateUser && status === InteractionStatus.None),
                        ).subscribe(() => {
                            const accountList: AccountInfo[] = this.msalService.instance.getAllAccounts();
                            CALogger.logInfo(this.TAG, `[${this._scope}] all accounts: `, accountList);
                            if (accountList.length > 0) {
                                this.activeAccount = accountList[0];
                                try {
                                    // Create an authentication provider for the current user
                                    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
                                        this.msalService.instance as PublicClientApplication,
                                        {
                                            account: this.msalService.instance.getActiveAccount()!,
                                            scopes: OAuthSettings.scopes,
                                            interactionType: InteractionType.Redirect
                                        }
                                    );

                                    // Initialize the Graph client
                                    this.graphClient = Client.initWithMiddleware({ authProvider: authProvider });
                                }
                                catch (ex) {
                                    CALogger.logError(this.TAG, 'init azure client failed. ex: ', ex);
                                }
                                this.loginStatusChanged.emit({ isLogin: true, account: this.activeAccount, scope: this._scope });
                            }
                            else {
                                this.loginStatusChanged.emit({ isLogin: false, scope: this._scope });
                            }
                        });
                    }
                    break;
                default:
                    {
                        timer(2000).subscribe(() => {
                            this.loginStatusChanged.emit({ isLogin: false, scope: this._scope });
                        });
                    }
                    break;
            }
        }
    }

    signin(providerSvc: CalendarService): Observable<{ isFault: boolean, pairingCode?: string, errorMessage?: string }> {
        switch (this._scope) {
            case CalendarScope.Azure:
                {
                    return of(this.msalGuardConfig.interactionType).pipe(
                        concatMap((interactionType: InteractionType) => {
                            if (interactionType === InteractionType.Popup) {
                                if (this.msalGuardConfig.authRequest) {
                                    return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).pipe(
                                        map((response: AuthenticationResult) => {
                                            this.msalService.instance.setActiveAccount(response.account);
                                            return { isFault: false };
                                        })
                                    );
                                } else {
                                    return this.msalService.loginPopup().pipe(
                                        map((response: AuthenticationResult) => {
                                            this.msalService.instance.setActiveAccount(response.account);
                                            return { isFault: false };
                                        })
                                    );
                                }
                            } else {
                                if (this.msalGuardConfig.authRequest) {
                                    this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
                                } else {
                                    this.msalService.loginRedirect();
                                }

                                return of({ isFault: false });
                            }
                        })
                    );
                }
            case CalendarScope.ServiceNow:
                {
                    return of(true).pipe(
                        concatMap(() => {
                            if (environment.system.lockByIAdea && !this.iadeaSvc.isIAdeaDevice(navigator.userAgent)) {
                                throw 'Not an IAdea device';
                            }

                            return this.iadeaSvc.getDeviceSignature().pipe(concatMap(ret => this.iadeaSvc.getDevicePairingCode(ret.data.playerId)));
                        }),
                        concatMap((devIdentity: { uuid: string, pairingCode: string }) => {
                            CALogger.logInfo(this.TAG, 'device identity: ', devIdentity);
                            this._deviceIdentity = devIdentity;

                            return from(providerSvc.getAccount(this._deviceIdentity.uuid));
                        }),
                        map((res: { isFault: boolean, data?: any, error?: string | number, errorMessage?: string }) => {
                            CALogger.logInfo(this.TAG, 'device info from plugin server: ', res);

                            if (res.isFault) {
                                throw res.errorMessage;
                            }

                            this.loginStatusChanged.emit({
                                isLogin: true,
                                account: {
                                    tenantId: res.data.tenantUrl,
                                    username: res.data.space.spaceID,
                                    localAccountId: res.data.username,
                                    homeAccountId: res.data.username,
                                    environment: res.data.registerAccountID,
                                    idToken: res.data.token,
                                    other: { deviceID: this._deviceIdentity.uuid },
                                    space: res.data.space
                                },
                                scope: this._scope
                            });

                            return { isFault: false };
                        }),
                        catchError(ex => {
                            CALogger.logError(this.TAG, 'singin failed. ex: ', ex);
                            return of({ isFault: true, error: ex instanceof HttpErrorResponse ? ex.message : ex })
                        })
                    );
                }
        }
    }

    signout() {
        this.activeAccount = null;
        switch (this._scope) {
            case CalendarScope.Azure:
                {
                    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
                        this.msalService.logoutPopup({
                            postLogoutRedirectUri: environment.auth.redirectUri,
                            mainWindowRedirectUri: "/"
                        });
                    } else {
                        this.msalService.logoutRedirect({
                            postLogoutRedirectUri: environment.auth.redirectUri
                        });
                    }
                }
                break;
            case CalendarScope.ServiceNow:
                {
                    this.loginStatusChanged.emit({ isLogin: false, scope: this._scope });
                }
                break;
        }
    }
}