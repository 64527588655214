import { CalendarScope, UITemplate } from "src/app/calendar/lib/calendar.data";
import { version } from "version";

export const globalSetting = {
    version: version,
    scope: CalendarScope.Azure,
    template: UITemplate.Basic,
    resource: {
        logo: '/assets/resources/IAdea-logo.png',
        bg: '/assets/resources/IAdea-bg.jpg',
        badge: '/assets/resources/badge.png'
    },
    license: {
        trial: true,
        statement: 'This is a test site, subject to change or close without notice. It is not legal to use it for production purposes. Please do not use it in a production environment.'
    },
    configFolderName: 'WorkplaceBooking',
    configFilename: 'config.json',
    config: {
        locale: 'en-US',
        pin: 1688,
        fontsizeRatio: 1,
        theme: {
            foreground: "#000000",
            availableColor: "#1cbf92",
            busyColor: "#bb2323",
            timeline: {
                bgColor: "#d3d3d3",
                futureEventTimeBlockColor: "#c96565",
                currentEventTimeBlockColor: "#d72c2c",
                expiredEventTimeBlockColor: "#6c757d"
            }
        },
        calendar: {
            enableOnsiteBook: true,
            enableOnsiteExtend: true,
            enableOnsiteCancelOrStop: true,
            enableFutureEventBook: true,
            enableFutureEventCancel: true,
            hideTimeline: false,
            hideHeader: false,
            showUnBusyEvent: false,
            showQRCodeAlways: false,
            showCancelledEvent: false
        },
        lightbar: {
            available: {
                color: "#00ff00",
                mode: "on"
            },
            busy: {
                color: "#ff0000",
                mode: "on"
            }
        }
    },
    badge: {
        bg: 'badge.png'
    }
}