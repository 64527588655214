import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarAction } from '../lib/calendar.data';
import { IDlgFuncComponent } from './dlg-func.data';
import { CalendarService } from '../lib/calendar.service';

@Component({
    templateUrl: './qrcode-dlg.component.html'
})
export class QRCodeDlgComponent implements IDlgFuncComponent<{ lastLoginAccount: string, isOnline: boolean }, void>, OnInit {
    title: string;
    action: CalendarAction;
    data?: { lastLoginAccount: string, isOnline: boolean };
    onApprove: (action: CalendarAction, data?: void) => void;
    onReject: (action: CalendarAction, data?: void) => void;

    _data: string;

    constructor(private calendarSvc: CalendarService, private translateSvc: TranslateService, private location: Location) { }

    ngOnInit(): void {
        this.translateSvc.get('lang.word.adhocEventTitle').subscribe((adhocstr: string) => {
            this.calendarSvc.getQRCodeLink({ title: adhocstr, lang: this.translateSvc.currentLang }).then((res: { isFault: boolean, data?: string, error?: string | number, errorMessage?: string }) => {
                this._data = res.data;
            });
        });

    }
}