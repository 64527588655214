import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { AccountInfo } from '@azure/msal-browser';
import { from, Subject, timer } from 'rxjs';
import { concatMap, switchMap, takeUntil } from 'rxjs/operators';

import { AuthService } from './lib/auth.service';
import { CacheService } from './lib/cache.service';
import { Helper } from './lib/helper';
import { CalendarService } from './calendar/lib/calendar.service';
import { CalendarScope } from './calendar/lib/calendar.data';
import { environment } from 'src/environments/environment';
import { CALogger } from './lib/logger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly TAG: string = 'app';
  private readonly _appAutoUpdateNotify$ = new Subject<void>();
  private readonly _heartbeat$: Subject<void> = new Subject<void>();
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private swUpdates: SwUpdate,
    private router: Router,
    private authSvc: AuthService,
    private cacheSvc: CacheService,
    private calendarSvc: CalendarService) {
    // update html title
    document.title = environment.title;
  }

  ngOnInit(): void {
    CALogger.logInfo(this.TAG, `sw enabled?: ${this.swUpdates.isEnabled}, scope?: ${this.calendarSvc.scope}`);

    // navigate to specific path
    const url: URL = new URL(window.location.href);
    CALogger.logInfo(this.TAG, 'url: ', url);
    if (url.searchParams.has('path')) {
      const targetRoute: string = url.searchParams.get('path');
      CALogger.logInfo(this.TAG, `target path: ${targetRoute}`);
      url.searchParams.delete('path');
      const searchKeyPair: { [key: string]: string } = {};

      url.searchParams.forEach((v: string, key: string) => {
        searchKeyPair[key] = v;
      });

      const fragments: { name: string, value: any }[] = url.hash.substring(1).split('&').map((pair: string) => {
        const splits = pair.split('=');
        return { name: splits[0], value: splits[1] }
      });

      fragments.forEach(fragement => searchKeyPair[fragement.name] = fragement.value);
      CALogger.logInfo(this.TAG, 'search key pair:', searchKeyPair);

      if (Helper.isTopWindow) {
        CALogger.logInfo(this.TAG, `route to the target path: ${targetRoute} with query params`, searchKeyPair);
        this.router.navigate(['/' + targetRoute], { queryParams: searchKeyPair });
      }
      else {
        // special case for issue on webview 107
        // window.history.pushState() in iframe will make userAgent lose some info after page reload
        if (targetRoute == 'token-parser') {
          const token: string = fragments.find(fra => fra.name === 'id_token')?.value;
          if (token) {
            CALogger.logInfo(this.TAG, 'post token msg');
            parent.postMessage(token, window.location.href);
          }
        }
      }

      return;
    }

    if (Helper.isTopWindow) {
      if (this.calendarSvc.scope === CalendarScope.Mockup) {
        this.router.navigate(['/calendar']);
        return;
      }

      this.initSelfHeartbeat();

      const url: URL = new URL(window.location.href);
      const license: string = url.searchParams.get('license');
      const subpath: string = url.pathname;
      const queryParams = {};
      url.searchParams.forEach((v, k) => {
        queryParams[k] = v;
      });

      // to support trial license from query params.
      if (license == 'trial') {
        environment.license.trial = true;
      }

      this.calendarSvc.setScope(environment.scope);

      this.authSvc.loginStatusChanged.pipe(
        takeUntil(this._destroying$)
      ).subscribe(async (status: { isLogin: boolean, account?: AccountInfo, scope: CalendarScope }) => {
        if (subpath === '' || subpath === '/' || subpath.startsWith('/login') || subpath.startsWith('/calendar')) {
          if (status.isLogin) {
            CALogger.logInfo(this.TAG, '-> login', status);
            from(this.cacheSvc.saveLoginAccount(status.account.username)).subscribe(() => {
              this.router.navigate(['/calendar'], { queryParams: queryParams });
            });
          }
          else {
            CALogger.logInfo(this.TAG, '-> logout');
            this.router.navigate(['/login'], { queryParams: queryParams });
          }

          if (this.calendarSvc.scope === CalendarScope.ServiceNow) {
            this._heartbeat$.next();
          }
        }
      });

      this.initServiceWorker();
    }
  }

  private initServiceWorker(): void {
    if (this.swUpdates.isEnabled && Helper.isTopWindow) {
      //sw update available notification
      this.swUpdates.versionUpdates.pipe(
        takeUntil(this._destroying$)
      ).subscribe((ev: VersionEvent) => {
        if (ev.type == 'VERSION_DETECTED') {
          CALogger.logInfo(this.TAG, '[sw] VERSION_DETECTED event:', ev);
          this._appAutoUpdateNotify$.next();
        }
      });

      //sw activated notification
      from(this.swUpdates.activateUpdate()).pipe(
        takeUntil(this._destroying$)
      ).subscribe(ev => {
        CALogger.logInfo(this.TAG, '[sw] new app version is activated', ev);
      });

      //add alert when sw is unrecoverable
      this.swUpdates.unrecoverable.pipe(
        takeUntil(this._destroying$)
      ).subscribe(ev => {
        CALogger.logInfo(this.TAG, '[sw] unrecoverable: ', ev.reason);
      });

      //check app update 5 minutes after page is load at first time, and then check every 60 minutes.
      timer(300000, 3600000).pipe(
        takeUntil(this._destroying$)
      ).subscribe(() => {
        if (window.navigator.onLine) {
          CALogger.logInfo(this.TAG, '[sw] check for update');
        }
      });

      //if new app is available, do auto update 1 minute later.
      this._appAutoUpdateNotify$.pipe(
        switchMap(() => {
          CALogger.logInfo(this.TAG, '[sw] begin waiting for app auto-update');
          return timer(30000);
        }),
        concatMap(() => from(this.swUpdates.activateUpdate())),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        document.location.reload();
      });
    }
  }

  private initSelfHeartbeat(): void {
    CALogger.logInfo(this.TAG, `is adapi support ? ${(window as any).adapi ? 'true' : 'false'}`);

    if ((window as any).adapi) {
      this._heartbeat$.pipe(
        switchMap(() => timer(30000, 30000).pipe(
          takeUntil(this._destroying$)
        )),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        (window as any).adapi.keepAlive();
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
