import { EventEmitter } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { IAConfig } from 'src/app/app-config.model';

export const BOOK_DURATIONS: number[] = [15, 30, 45, 60, 90, 120];

export enum CalendarScope {
    Unknown = 'Unknown',
    Mockup = 'Mockup',
    Azure = 'Azure',
    ServiceNow = 'ServiceNow'
}

export enum UITemplate {
    Basic = 'Basic',
    Demo1 = 'Demo1'
}

export interface ICalendarWorkplace {
    TAG: string;
    // for the managed account
    account: AccountInfo;
    // for resource, ex: room
    resourceAccount: { id?: string, username: string, name: string };
    token: string;

    isUnpaired(errorCode: string | number): boolean;
    getAccount(deviceIdentityId: string): Promise<{ isFault: boolean, data?: any, error?: string | number, errorMessage?: string }>;
    getRooms(): Promise<{ isFault: boolean, data?: any[], error?: string | number, errorMessage?: string }>;
    getCalendarByDate(d: Date, force: boolean): Promise<{ isFault: boolean, data?: { calendar: IAEventInfo[], space: SpaceInfo, lastConfigUpdateTime?: number }, error?: string | number, errorMessage?: string }>;
    extendEvent(event: IAEventInfo, durationInMinute: number): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }>;
    addEvent(subject: string, startDate: Date, endDate: Date, isAllDay: boolean): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }>;
    stopEvent(event: IAEventInfo, stopDateTime: Date): Promise<{ isFault: boolean, data?: Date, error?: string | number, errorMessage?: string }>;
    cancelEvent(event: IAEventInfo, comment?: string): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }>;
    declineEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }>;
    checkInEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }>;
    checkOutEvent(event: IAEventInfo): Promise<{ isFault: boolean, error?: string | number, errorMessage?: string }>;
    getConfig(): Promise<{ isFault: boolean, data?: IAConfig, error?: string | number, errorMessage?: string }>;
    getBlobFile(relPath: string): Promise<{ isFault: boolean, data?: { content: Blob, mimeType?: string }, error?: string | number, errorMessage?: string }>;
    getTextFile(relPath: string): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string}>;
    getQRCodeLink(options?: { title: string, lang: string }): Promise<{ isFault: boolean, data?: string, error?: string | number, errorMessage?: string}>;

    onActiveAccountChanged?: EventEmitter<{ name: string; username: string; }>;
}

export type PartialEvent = Partial<MicrosoftGraph.Event & { ['@odata.etag']: string }>;
export class IAEventInfo {
    id: string;
    etag: string;
    attendees?: MicrosoftGraph.Attendee[];
    body?: {
        contentType?: string;
        content?: string;
    };
    createdDateTime?: string;
    startDate?: Date;
    endDate?: Date;
    isAllDay?: boolean;
    isCancelled?: boolean;
    isOrganizer?: boolean;
    organizer?: { address?: string, name?: string };
    subject?: string;
    webLink?: string;
    allowNewTimeProposals?: boolean;
    isPrivate?: boolean;
    isCheckin?: boolean;
    checkinState?: string;
    showAs?: string;

    constructor(raw?: any) {
        if (raw) {
            this.id = raw.id;
            this.etag = raw['@odata.etag'];
            this.attendees = raw.attendees;
            this.body = raw.body;
            if (raw.start?.dateTime) {
                const startTime: string = raw.start?.dateTime;
                const isUTCNeeded: boolean = (raw.start?.timeZone === 'UTC' || !raw.start?.timeZone) && startTime.lastIndexOf('Z') < 0  ? true : false;
                this.startDate = new Date(raw.start?.dateTime + (isUTCNeeded ? 'Z' : ''));
            }
            else {
                this.startDate = null;
            }

            if (raw.end?.dateTime) {
                const endTime: string = raw.end?.dateTime;
                const isUTCNeeded: boolean = (raw.end?.timeZone === 'UTC' || !raw.end?.timeZone) && endTime.lastIndexOf('Z') < 0  ? true : false;
                this.endDate = new Date(raw.end?.dateTime + (isUTCNeeded ? 'Z' : ''));
            }
            else {
                this.endDate = null;
            }

            //trigger all-day event as independent event
            this.isAllDay = raw.isAllDay;
            this.isOrganizer = raw.isOrganizer;
            this.organizer = { address: raw.organizer?.emailAddress?.address, name: raw.organizer?.emailAddress?.name };
            this.isPrivate = raw.sensitivity === 'private';
            this.subject = this.isPrivate ? 'lang.word.privateEvent' : raw.subject;
            this.webLink = raw.webLink;
            this.isCheckin = raw.isCheckin;
            this.isCancelled = raw.isCancelled;
            this.checkinState = raw.checkinState;
            this.allowNewTimeProposals = raw.allowNewTimeProposals;
            this.showAs = raw.showAs || 'busy';
        }
    }

    copy(): IAEventInfo {
        let ev: IAEventInfo = new IAEventInfo();
        ev.id = this.id;
        ev.etag = this.etag;
        ev.attendees = this.attendees;
        ev.body = this.body;
        ev.startDate = new Date(this.startDate);
        ev.endDate = new Date(this.endDate);
        ev.isAllDay = this.isAllDay;
        ev.isOrganizer = this.isOrganizer;
        ev.organizer = Object.assign({}, this.organizer);
        ev.isPrivate = this.isPrivate;
        ev.subject = this.subject;
        ev.webLink = this.webLink;
        ev.allowNewTimeProposals = this.allowNewTimeProposals;
        ev.isCheckin = this.isCheckin;
        ev.isCancelled = this.isCancelled;
        ev.showAs = this.showAs;

        return ev;
    }
}

export class ICalendarAlert {
    type: string;
    date: Date;
    detail: string;
    detailParams?: { [paramIndex: string]: any };
    isView?: boolean;
}

export enum ErrorType {
    Size,
    Format,
    API,
    Network,
    Internal,
    Notify
}

export enum CalendarAction {
    EndEvent,
    CancelEvent,
    Extend,
    AddEvent,
    Checkin,
    Checkout,
    Refresh,
    FindRoom,
    Notification,
    GetDrive,
    QRCode,
    Disconnect,
    AdjustDate,
    AuthenticateByPin,
    None
}

export enum Orientation {
    Portrait,
    Landscape
}

export class ITimelineData {
    hour: number;
    minute: number;
    str: string;
}

export interface ITimelineBusyEvent {
    subject: string;
    begin: number;
    end: number;
    fakeID: string;
    isExpired?: boolean;
    isInProcess?: boolean;
    source: IAEventInfo;
    isOverlap?: boolean;
    overlapOffsetLeft?: number;
    overlapWidth?: number;
    overlapRaio?: number;
    highlight?: boolean;
}

export interface ICalendarResponse<T> {
    "@odata.context": string;
    value: T;
}

export interface IDriveItemInfo {
    cTag: string,
    createdBy: {
        user: {
            displayName: string,
            email: string,
            id: string
        }
    },
    createdDateTime: string,
    eTag: string,
    folder?: {
        childCount: number
    },
    file?: {
        hashes: {
            quickXorHash: string
        },
        mimeType: string
    },
    lastModifiedBy: {
        user: {
            displayName: string,
            email: string,
            id: string
        }
    },
    lastModifiedDateTime: string,
    name: string, //file or folder name
    id: string,
    size: number,
    webUrl: string,
    parentReference: {
        driveId: string,
        driveType: string,
        id: string,
        path: string
    }
}

export class EventBookData {
    subject?: string;
    durationInMinute?: number;
    startDate?: Date;
    endDate?: Date;
    isAllDay?: boolean;
}

export class SpaceInfo {
    checkinRequired?: boolean;
    checkinBeforeMeetingMinute?: number;
    qrcodeLink?: string;
    occupancy?: OccupancyInfo;

    constructor(raw?: any) {
        this.checkinRequired = raw?.checkinRequired == '1' || raw?.checkinRequired == 'true' ? true : false;
        this.checkinBeforeMeetingMinute = parseInt(raw?.checkinBeforeMeetingMinute) || 15;
        this.qrcodeLink = raw?.qrcodeLink || '';
        this.occupancy = new OccupancyInfo(raw?.occupancy);
    }
}

export class OccupancyInfo {
    count?: number; // number of people in the space
    state?: string; // occupied | unoccupied
    eventTimestamp?: string;
    hasOccupancySensor: boolean;
    isOccupied: boolean;

    constructor(raw?: any) {
        this.hasOccupancySensor = raw?.state ? true : false;
        this.count = raw?.count ?? 0;
        this.state = raw?.state || 'unoccupied';
        this.eventTimestamp = raw?.eventTimestamp || null;
        this.isOccupied = this.state === 'occupied';
    }

    isChanged(compared: OccupancyInfo): boolean {
        return this.hasOccupancySensor !== compared.hasOccupancySensor || this.isOccupied !== compared.isOccupied ? true : false;
    }
}